import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  constructor() { }
  globalFunction(): void {
    // Your global function logic here
    console.log('This is a global function');
  }
  generateBarcode() {
    console.log('rrr');
    // Taking input values from the user

    // Text value
    let inputValue = '15578000';
    // Barcode type
    let barcodeType = 'code128';
    // Renederer type
    let rendererType = 'bmp';

    // Settings to generate barcode
    let settings = {
      output: rendererType,
      bgColor: '#FFFFFF',
      color: '#000000',
      barWidth: '1.5',
      barHeight: '70',
      moduleSize: '5',
      posX: '15',
      posY: '30',
      addQuietZone: '1'
    };
      // If renderer type is not canvas, show barcodeoutput div and
      // add output from barcode() function to that div
      $("#barcodeoutput").html("").show();
      //@ts-ignore
      return $("#barcodeoutput").barcode(inputValue,barcodeType,settings);
  }
}
