<p-toast [style]="{width: '30%'}"></p-toast>
<p-blockUI [blocked]="blockedDocument">
  <div class="centered">
      <i class="pi pi-spin pi-spinner" style="font-size: 4rem"></i>
     المرجوا الانتظار ...
  </div>
</p-blockUI>

<div class="area">
  <p-card [style]="{ 'margin-left': '30px' }">
    <p-menubar [model]="items">
      <ng-template pTemplate="start">
          <img src="assets/logo.png" height="40" class="p-mr-2">
      </ng-template>
      <ng-template pTemplate="end">
          <a class="navbar-brand" (click)="navigateUrl('GestionUser')" style="cursor:pointer;">
              <img src="../assets/userLogo.png" alt="Logo" style="width:40px;" class="rounded-pill">
          </a>
          <span style="margin-left: 15px"></span>
          <a class="navbar-brand" (click)="logout()" style="cursor:pointer;">
              <img src="../assets/logout.png" alt="Logo" style="width:40px;" class="rounded-pill">
          </a> 
      </ng-template>
    </p-menubar>
    <p-menubar [model]="itemsUser">
      <ng-template pTemplate="start">
          <img src="assets/logo.png" height="40" class="p-mr-2">
      </ng-template>
      <ng-template pTemplate="end">
          <a class="navbar-brand" (click)="logout()" style="cursor:pointer;">
              <img src="../assets/logout.png" alt="Logo" style="width:40px;" class="rounded-pill">
          </a> 
      </ng-template>
    </p-menubar>
  </p-card>
   <p-card [style]="{ 'margin-left': '30px'}">
    <div class="container">
      <div class="child">
        <button
          style="float: right;"
          menu="button"
          pButton
          icon="pi pi-replay"
          iconPos="right"
          (click)="menu();findUser(User.idUser)"
          label="Reset"
        ></button>         
      </div>
      <div class="child">
        <button
          [disabled]="!User.idUser"
          style="float: right;"
          menu="button"
          pButton
          icon="pi pi-plus"
          iconPos="right"
          (click)="save()"
          label="Ajouter a l'utilisateur"
        ></button>        
      </div>
      <div class="child">
        <!-- {{User |json}} -->
        <p-dropdown
          [options]="Users"
          [(ngModel)]="User.idUser"
          filter="true"
          placeholder="Select utilisateur"
          autoWidth="false"
          [style]="{ width: '100%' }"
          [showClear]="true"
          (click)="findUser(User.idUser)"
        >
          <ng-template let-item pTemplate="selectedItemType">
            <span style="vertical-align: middle; margin-left: 0.5em">
              {{ item.label }}
            </span>
          </ng-template>
          <ng-template let-car pTemplate="item">
            <div class="ui-helper-clearfix" style="height: 25px">
              <div
                style="
                  font-size: 14px;
                  float: left;
                  margin-top: 4px;
                  text-align: center;
                "
              >
                {{ car.label }}
              </div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
   </p-card>
   <p-card [style]="{ 'margin-left': '30px' }">
    <div class="container">
      <div class="child">
        <textarea rows="5" cols="40" pInputTextarea autoResize="autoResize" [(ngModel)]="menuItem" (ngModelChange)="changeMenu($event)"></textarea>
      </div>
      <div class="child">
        <textarea rows="5" cols="40" pInputTextarea autoResize="autoResize" [(ngModel)]="menuUtilisateur" (ngModelChange)="changeMenu($event)" style="display: inline-block;"></textarea>
      </div>
    </div>
    
    
   </p-card>
</div>