import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../data.service';
import { HttpClient } from '@angular/common/http';
import { MessageService, SortEvent } from 'primeng/api';
import { Eap } from '../model/eap';
import { AuthenticationService } from '../authentication.service';

@Component({
  selector: 'app-groupe',
  templateUrl: './groupe.component.html',
  styleUrls: ['./groupe.component.scss'],  
  providers: [MessageService]
})
export class GroupeComponent implements OnInit {

  Eap             : Eap = {};
  Eaps            : Eap[];
  khatamat        : Eap[];
  rythme          : any[];
  selectedValues  : string[] = [];  
  selectedType    : Eap = {};
  blockedDocument : boolean;
  displayDialog   : boolean=false;
  dataTable       : string;
  innerWidth      : number;
  innerHeight     : number;
  dateSituation   : string;
  idHizb          : string;
  constructor(
    private data: DataService,     
    public AuthenticationService: AuthenticationService,
    private http: HttpClient,
    private messageService: MessageService,
    private router: Router,
    ) { }

  ngOnInit(): void {
    this.dateSituation = new Date().toISOString().split("T")[0];
    this.rythme=[{'value':1,'label':'حزب واحد'}, {'value':2,'label':'حزبين اثنين'}]
    this.donnees();
    this.innerWidth = window.innerWidth;
    this.innerHeight = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight)-120;
  }
  donnees() {
    this.dataTable = "groupe";
    this.Eap.action = "Select";
    this.data
      .donnee(this.dataTable,this.Eap)
      .toPromise()
      .then((x: Eap[]) => (this.Eaps = x));
  }
  delete() {
    if (confirm("êtes vous sure?")) {
      this.dataTable = "groupe";
      this.Eap.action = "Delete";
      this.data
        .donnee(this.dataTable,this.Eap)
        .toPromise()
        .then((res: { message: string }) => {
          if ((res.message = "Opération de supression réuissie")) {
            this.messageService.add({severity:'warn', summary: 'Supression', detail: res.message});
            this.displayDialog = false;
            this.donnees();
          } else {
            alert("Nonnnn");
          }
        });
    }
  }
  save() {
    if(!this.Eap.rythme){
      this.Eap.rythme='1';
    }
    if (this.Eap.designation == null || this.Eap.designation =="" ) {
      this.messageService.add({severity:'warn', summary: 'Sauvegarde', detail: "Il manque quelque chose !!!"});
    } else {
      if (this.Eap.id) {
        this.blockedDocument=true;
        this.dataTable = "groupe";
        this.Eap.action = "Update";
        this.Eap.idUser = this.AuthenticationService.currentUserValue.id;
        this.data
          .donnee(this.dataTable,this.Eap)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.blockedDocument=false;
              this.displayDialog=false;
              this.donnees();
            } else {
              this.blockedDocument=false;
              this.displayDialog=false;
              this.messageService.add({severity:'warn', summary: 'Attention', detail: res.message});
            }
          });
      } else {
        this.blockedDocument=true;
        this.dataTable  = "groupe";
        this.Eap.action = "Insert";
        this.Eap.idUser = this.AuthenticationService.currentUserValue.id;
        console.log(this.Eap);        
        this.data
          .donnee(this.dataTable,this.Eap)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.blockedDocument=false;
              this.displayDialog=false;
              this.donnees();
            } else {
              alert(res.message);
            }
          });
      }
    }
  }
  newDonnee: boolean;
  showDialogToAdd() {
    this.newDonnee = true;
    // this.file="";
    // this.imageUrl = this.myUrl + "/API/api/uploads/default.png";
    this.Eap = {};
    this.displayDialog = true;
  }
  onRowSelect(event) {
    console.log(event);
    // this.file="";
    this.newDonnee = false;
    // if (event.data.images == null) {
    //   var leet = this.myUrl + "/API/api/uploads/default.png";
    // }else{
    //   var leet = this.myUrl + "/API/api/uploads/images/" + event.data.images;     
    // }
    // this.imageUrl = leet;
    // alert(leet);
    this.Eap = this.cloneDonnee(event.data);
    this.displayDialog = true;
  }
  cloneDonnee(c: Eap): Eap {
    let Type = {};
    for (let prop in c) {
      Type[prop] = c[prop];
    }
    return Type;
  }
}
