<div class="area" >
    <p-toast [style]="{width: '350px'}"></p-toast>
  
  <p-table
    dir="rtl"
    #dt1
    [value]="Eaps"
    selectionMode="single"
    [(selection)]="selectedType"
    (onRowSelect)="onRowSelect($event)"
    [paginator]="true"
    [rows]="10"
    >
    <ng-template pTemplate="header">
      <tr>
        <th>
          <button
            style="float: right; margin-top: 10px; margin-right: 10px"
            type="button"
            pButton
            icon="pi pi-plus"
            iconPos="right"
            (click)="showDialogToAdd()"
            label="اضافة المجموعة "
          ></button>
        </th>
        <th>
          <input 
          style="width: 100%;" 
          pInputText type="text" 
          (input)="dt1.filter($event.target.value, 'designation', 'contains')" 
          [value]="dt1.filters['designation']?.value" 
          placeholder="المجموعة" 
          class="p-column-filter">
        </th>
      </tr>
      <tr>
        <th [pSortableColumn]="'rythme'" style="text-align: center"><p-sortIcon [field]="'rythme'"></p-sortIcon> عدد الأحزاب
        </th>
        <th [pSortableColumn]="'designation'" style="text-align: center"><p-sortIcon [field]="'designation'"></p-sortIcon> المجموعة

        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-Type>
      <tr
        [pSelectableRow]="Type"
        (mouseover)="changeText = Type.images"
        (mouseout)="changeText = false"
      >
        <td style="text-align: center">{{ Type.rythme }}</td>
        <td style="text-align: center">{{ Type.designation }}</td>
      </tr>
    </ng-template>
  </p-table> 
</div>
<p-dialog
  header="المجموعة"
  [(visible)]="displayDialog"
  [focusOnShow]="false"
  [responsive]="true"
  showEffect="fade"
  [modal]="true"
  [style]="{ width: '50%' }"
>
<div class="container">
  <div class="child">
    <span class="p-float-label">
      <input
        dir="rtl"
        id="designation"
        type="text"
        size="30"
        pInputText
        [(ngModel)]="Eap.designation"
        (click)="$event.target.select()" required
        style="text-align: center;"
      />
      <label htmlFor="designation">Désignation</label>
    </span>
  </div>
  <div class="child">
    {{Eap.type}}
    <p-dropdown 
      [style]="{'width': '180px'}"
      [options]="rythme" 
      [(ngModel)]="Eap.rythme" 
      placeholder=""
      >
  </p-dropdown>
  </div>
</div>
<br><br><br><br>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-helper-clearfix">
      <button
        style="float: right; margin-top: 10px; margin-right: 10px"
        type="button"
        pButton
        icon="pi pi-sign-out"
        (click)="displayDialog=false"
        label="Fermer"
      ></button>
      <button
        type="button"
        style="float: left; margin-top: 10px; margin-right: 10px;background-color: red;"
        pButton
        class="ui-button-danger"
        icon="pi pi-trash"
        (click)="delete()"
        label="Supprimer"
      ></button>
      <button
        style="float: right; margin-top: 10px; margin-right: 10px"
        type="button"
        pButton
        icon="pi pi-save"
        (click)="save()"
        label="Sauvegarder"
      ></button>
    </div>
  </p-footer>
</p-dialog>

