<p-toast [style]="{width: '30%'}"></p-toast>
<p-blockUI [blocked]="blockedDocument">
  <div class="centered">
      <i class="pi pi-spin pi-spinner" style="font-size: 4rem"></i>
      Veuillez patienter ...
  </div>
</p-blockUI>
<div class="area">
  <p-card [style]="{ 'margin-left': '30px' }">
    <h1 class="ribbon">
      Gestion des Comptes
      <button
        style="float: right; margin-bottom: 10px; margin-right: 10px"
        type="button"
        pButton
        icon="pi pi-plus"
        iconPos="right"
        (click)="showDialogToAdd()"
        label="Ajouter un nouveau Menu "
      ></button>
    </h1>
    <p-table
      [value]="tableDonnees"
      selectionMode="single"
      (onRowSelect)="onRowSelect($event)"
      [paginator]="true"
      [rows]="5"
    >
    <ng-template pTemplate="caption">
      <div class="p-d-flex">
          <button type="button" pButton pRipple icon="pi pi-file-o" (click)="dt.exportCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
          <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportAsXLSX()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
          <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf()" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
          <button type="button" pButton pRipple icon="pi pi-filter" (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto" pTooltip="Selection Only" tooltipPosition="bottom"></button>
      </div>
  </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th  [pSortableColumn]="'id'"><p-sortIcon [field]="'id'"></p-sortIcon> id</th>
          <th  [pSortableColumn]="'idMenu'"><p-sortIcon [field]="'idMenu'"></p-sortIcon> idMenu</th>
          <th  [pSortableColumn]="'label'"><p-sortIcon [field]="'label'"></p-sortIcon>label</th>
          <th  [pSortableColumn]="'icon'"><p-sortIcon [field]="'icon'"></p-sortIcon>icon</th>
          <th  [pSortableColumn]="'routerLink'"><p-sortIcon [field]="'routerLink'"></p-sortIcon>routerLink</th>
          <th>Images</th>
        </tr>
        <tr>
          <th></th>
          <th></th>
          <th>
            <input
            pInputText
            type="text"
            (input)="filter($event.target.value, 'label')"
            placeholder="Désignation"
            style="width: 100%"
            class="ui-column-filter"
          />
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-Type>
        <tr
          [pSelectableRow]="Type"
          (mouseover)="changeText = Type.images"
          (mouseout)="changeText = false"
        >
          <td>{{ Type.id }}</td>
          <td>{{ Type.idMenu }}</td>
          <td>{{ Type.label }}</td>
          <td>{{ Type.icon }}</td>
          <td>{{ Type.routerLink }}</td>
          <td align="center">
            <img
              *ngIf="Type.images"
              src="{{ this.myUrl }}/src/assets/images/{{ Type.images }}.png"
              height="40px"
              width="40px"
            />
          </td>
        </tr>
      </ng-template>
    </p-table>
  </p-card>  
</div>

<p-dialog
  header="Paramètres"
  [(visible)]="displayDialog"
  [focusOnShow]="false"
  [responsive]="true"
  showEffect="fade"
  [modal]="true"
  [style]="{ width: '700px' }"
>
<br>
    <div class="p-grid">
        <div class="p-col">
            <div class="container">                                    
                <div class="child">
                  <span class="p-float-label">
                    <input type="text" id="inputtext1" [(ngModel)]="Donnee.idMenu" pInputText style="width: 50px;"> 
                    <label for="inputtext1">idMenu</label>
                  </span>
                </div>                                     
                <div class="child">
                  <span class="p-float-label">
                    <input type="text" id="inputtext2" [(ngModel)]="Donnee.label" pInputText> 
                    <label for="inputtext2">label</label>
                  </span>
                </div>
                <div class="child">
                  <span class="p-float-label">
                    <input type="text" id="inputtext3" [(ngModel)]="Donnee.routerLink" pInputText> 
                    <label for="inputtext3">routerLink</label>
                  </span>
                </div>
                <div class="child">
                  <span class="p-float-label">
                    <input type="text" id="inputtext3" [(ngModel)]="Donnee.images" pInputText> 
                    <label for="inputtext3">images</label>
                  </span>                 
                </div>  
            </div>
            <div class="container">
                <div class="child">
                  <span class="p-float-label">
                    <input type="text" id="inputtext4" [(ngModel)]="Donnee.icon" pInputText style="width: 50px;"> 
                    <label for="inputtext4">icon</label>
                  </span>
                </div>                                     
                <div class="child">
                  <span class="p-float-label">
                    <input type="text" id="inputtext5" [(ngModel)]="Donnee.leaf" pInputText> 
                    <label for="inputtext5">leaf</label>
                  </span>
                </div>
                <div class="child">
                  <span class="p-float-label">
                    <input type="text" id="inputtext6" [(ngModel)]="Donnee.data" pInputText> 
                    <label for="inputtext6">data</label>
                  </span>
                </div>

            </div>
        </div>
    </div>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-helper-clearfix">
      <button
        type="button"
        pButton
        class="ui-button-danger"
        icon="pi pi-times"
        (click)="delete()"
        label="Supprimer"
      ></button>
      <button
        type="button"
        pButton
        icon="pi pi-check"
        (click)="save()"
        label="Sauvegarder"
      ></button>
    </div>
  </p-footer>
</p-dialog>
