import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "../authentication.service";
import { Router, ActivatedRoute } from "@angular/router";
import { first } from "rxjs/operators";
import { User } from "../model/user";
import { environment } from "src/environments/environment";
import { MessageService } from "primeng/api";
// import { loadCldr, L10n } from "@syncfusion/ej2-base";

  // declare var require: any;
// loadCldr(
//     require('cldr-data/supplemental/numberingSystems.json'),
//     require('cldr-data/main/fr/ca-gregorian.json'),
//     require('cldr-data/main/fr/numbers.json'),
//     require('cldr-data/main/fr/timeZoneNames.json'),
//     require('cldr-data/supplemental/weekdata.json'));
@Component({
  selector: "app-login",
  templateUrl: "login.component.html",
  styleUrls: ["login.component.scss"],
  providers: [MessageService],
})
export class LoginComponent implements OnInit {
  myUrl: string = `${environment.urlApi}`;
  public minDate: Date = new Date ("02/01/2021");
  public maxDate: Date = new Date ("12/31/2023");
  public value: Date = new Date ();
  user: User = {};
  erreur = {};
  returnUrlAccueil: string;
  returnUrl: string;
  returnUrlAdmin: string;
  returnUrlOperateur: string;
  returnUrlAdministrateur: string;
  showHideClass:string ="pi pi-eye";
  inputType:string ="password";
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
  ) {this.erreur="";}

  ngOnInit() {
    // L10n.load({
    //   'fr': {
    //     'calendar': {
    //        today:"Aujourd'Huit"
    //     }
    //   }
    // });
    this.returnUrlAccueil =this.route.snapshot.queryParams["returnUrl"] || "/khatamat";
    this.returnUrl =this.route.snapshot.queryParams["returnUrl"] || "/khatamat";
    this.returnUrlAdministrateur =this.route.snapshot.queryParams["returnUrl"] || "/khatamat";
    this.returnUrlAdmin =this.route.snapshot.queryParams["returnUrl"] || "/khatamat";
    this.returnUrlOperateur =this.route.snapshot.queryParams["returnUrl"] || "/khatamat";
    document.body.className = "selector";
    this.authenticationService.logout();
  }
  login() {
    //console.log(this.user);
    this.authenticationService
      .login(this.user.username, this.user.password)
      .pipe(first())
      .subscribe(
        (data) => {
          // console.log(data);
          this.router.navigate([this.returnUrlAccueil]);
          // if (data.groupe == "Administrateur" || data.groupe == "Administrateurs"  ) {
          //   this.router.navigate([this.returnUrlAdministrateur]);
          // } else if (data.groupe == "Admin") {
          //   this.router.navigate([this.returnUrlAdmin]);
          // } else if (data.groupe == "User") {
          //   this.router.navigate([this.returnUrl]);
          // } else if (data.groupe == "Operateur") {
          //   this.router.navigate([this.returnUrlOperateur]);
          // }
        },
        (error) => {
          console.log(error);
          this.erreur =JSON.stringify(error);
          this.messageService.add({severity:'warn', summary: 'انتباه', detail: "حسابكم أو رقم سركم خاطئ"});
          //this.error = error;
          //this.loading = false;
        }
      );
  }
  showPassword(){
    if (this.showHideClass == 'pi pi-eye'){
      this.showHideClass = 'pi pi-eye-slash';
      this.inputType='text';
    }else{
      this.showHideClass = 'pi pi-eye';
      this.inputType='password';
    }
  }
  register() {
    this.router.navigate(["/register"]);
  }
}
