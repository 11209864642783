<div class="area" >
    <p-toast [style]="{width: '350px'}"></p-toast>
    <p-table
    dir="rtl"
    #dt1
    [value]="Eaps"
    selectionMode="single"
    [(selection)]="selectedType"
    (onRowSelect)="onRowSelect($event)"
    [paginator]="true"
    [rows]="10"
  >
  <ng-template pTemplate="header">
    <tr>
      <!-- <th [pSortableColumn]="'id'"><p-sortIcon [field]="'id'"></p-sortIcon>ID</th> -->
      <th [pSortableColumn]="'type'"><p-sortIcon [field]="'type'"></p-sortIcon>Type</th>
      <th [pSortableColumn]="'designationType'"><p-sortIcon [field]="'designationType'"></p-sortIcon>Type de Prière</th>
      <th [pSortableColumn]="'titre'"><p-sortIcon [field]="'titre'"></p-sortIcon>Titre</th>
      <th [pSortableColumn]="'designation'"><p-sortIcon [field]="'designation'"></p-sortIcon>Prière</th>
      <th [pSortableColumn]="'image'"><p-sortIcon [field]="'rythme'"></p-sortIcon> Images</th>
    </tr>
    <tr>
      <th>
        <input style="width: 100%;" pInputText type="text" (input)="dt1.filter($event.target.value, 'designationType', 'contains')" [value]="dt1.filters['designationType']?.value" placeholder="Type de Prière" class="p-column-filter">
      </th>
      <th>
        <input style="width: 100%;" pInputText type="text" (input)="dt1.filter($event.target.value, 'titre', 'contains')" [value]="dt1.filters['titre']?.value" placeholder="Titre" class="p-column-filter">
      </th>
      <th>
        <input style="width: 100%;" pInputText type="text" (input)="dt1.filter($event.target.value, 'designation', 'contains')" [value]="dt1.filters['designation']?.value" placeholder="Prière" class="p-column-filter">
      </th>
      <th>
        <button
          style="float: right; margin-top: 10px; margin-right: 10px"
          type="button"
          pButton
          icon="pi pi-plus"
          iconPos="right"
          (click)="showDialogToAdd()"
          label="اضافة دعاء "
      ></button>
      </th>      
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-User>
    <tr [pSelectableRow]="User">
      <td>{{ User.type }}</td>
      <td>{{ User.designationType }}</td>
      <td>{{ User.titre }}</td>
      <td>{{ User.designation }}</td>
      <td>{{ User.images }}</td>
    </tr>
  </ng-template>
  </p-table>
    <!-- <p-table
        #dt1
        [value]="Eaps"
        selectionMode="single"
        [(selection)]="selectedType"
        (onRowSelect)="onRowSelect($event)"
        [paginator]="true"
        [rows]="10"
    >
    <ng-template pTemplate="header">
      <tr>
        <th [pSortableColumn]="'designationType'"><p-sortIcon [field]="'designationType'"></p-sortIcon> Type de Prière</th>
        <th [pSortableColumn]="'designation'"><p-sortIcon [field]="'designation'"></p-sortIcon> Prière</th>
        <th>Images</th>
        <th>audio</th>
      </tr>
      <tr>
        <th>
          <input 
          style="width: 100%;" 
          pInputText type="text" 
          (input)="dt1.filter($event.target.value, 'designationType', 'contains')" 
          [value]="dt1.filters['designationType']?.value" 
          placeholder="Type de Prière" 
          class="p-column-filter">
        </th>
        <th>
          <input 
          style="width: 100%;" 
          pInputText type="text" 
          (input)="dt1.filter($event.target.value, 'designation', 'contains')" 
          [value]="dt1.filters['designation']?.value" 
          placeholder="Prière" 
          class="p-column-filter">
        </th>
        <th></th>
        <th>
            <button
                style="float: right; margin-top: 10px; margin-right: 10px"
                type="button"
                pButton
                icon="pi pi-plus"
                iconPos="right"
                (click)="showDialogToAdd()"
                label="اضافة دعاء "
            ></button>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-Type>
      <tr>
        <td>{{ Type.designationType }}</td>
        <td>{{ Type.designation }}</td>
        <td align="center">
          <img
            *ngIf="Type.image"
            src="{{ this.myUrl }}/API/api/uploads/images/{{ Type.image }}"
            height="50px"
            width="80px"
          />
        </td>
        <td></td>
      </tr>
    </ng-template>
  </p-table>  -->
</div>
<p-dialog
  header="الدعاء"
  [(visible)]="displayDialog"
  [focusOnShow]="false"
  [responsive]="true"
  showEffect="fade"
  [modal]="true"
  [style]="{ width: '50%' }"
>
<div class="container">
  <div class="child">
    <span class="p-float-label">
      <p-dropdown
        [options]="TypeD"
        [(ngModel)]="Eap.idType"
        placeholder="نوع الدعاء"
        id="Groupe"
        [showClear]="true"
        [style]="{ width: '100%' }">
        {{ Eap.idType }}</p-dropdown>
      <label htmlFor="Groupe">نوع الدعاء</label>
    </span>
  </div>
</div>
<div class="container">
  <div class="child">
    <span class="p-float-label">
      <input
        type="text"
        size="30"
        pInputText
        [(ngModel)]="Eap.titre"
        (click)="$event.target.select()" required
        style="text-align: center;width: 100%;"
      />
      <label htmlFor="username">العنوان</label>
    </span>
  </div>
</div>
<div class="container">
  <div class="child">
    <span class="p-float-label">
      <textarea
        dir="rtl"
        [(ngModel)]="Eap.designation"
        id="input"
        [style]="{ width: '100%' }" pInputTextarea></textarea>
      <label htmlFor="input">الدعاء</label>
    </span>
  </div>
</div>
<div class="container">
  <div class="child" style="display: flex; justify-content: space-between ;margin: 10px;"> 
    <div class="radio-toolbar" style="display: flex;">
      <!-- {{ModePaiements | json}} -->
      <div *ngFor="let type of typeFile">
        <input
          type="radio"
          id="radio{{type.label}}"
          name="radiosF"
          [value]="type.label"
          [(ngModel)]="Eap.type"
        />
        <label for="radio{{type.label}}">{{type.label}}
          <div *ngIf="type.label=='Link'">
            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"/></svg>
          </div>
          <div *ngIf="type.label=='Audio'">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 122.88 110.35" style="enable-background:new 0 0 122.88 110.35" xml:space="preserve"><g><path d="M6.32,0v6.22h12.22V0h6.32v14.88h73.15V0h6.32v6.22h12.22V0h6.32v110.35h-6.32v-7.19h-12.22v7.19h-6.32V94.79H24.87v15.56 h-6.32v-7.19H6.32v7.19H0V0H6.32L6.32,0z M73.58,60.81c0.93-0.16,1.81-0.13,2.58,0.05v-18.1l-19.16,5.5v21.02 c0.01,0.11,0.02,0.23,0.02,0.35c0,0,0,0,0,0c0,2.83-2.97,5.64-6.63,6.27c-3.66,0.63-6.63-1.15-6.63-3.98 c0-2.83,2.97-5.64,6.63-6.27c1.38-0.24,2.66-0.13,3.72,0.24l0-25.88h0.16l24.79-5.68v29.15c0.04,0.21,0.07,0.43,0.07,0.65 c0,0,0,0,0,0c0,2.36-2.48,4.71-5.54,5.24c-3.06,0.53-5.54-0.96-5.54-3.33C68.04,63.68,70.52,61.33,73.58,60.81L73.58,60.81 L73.58,60.81z M98.01,21.2H24.87v67.27h73.15V21.2L98.01,21.2z M116.56,96.84v-11.8h-12.22v11.8H116.56L116.56,96.84z M116.56,78.72v-11.8h-12.22v11.8H116.56L116.56,78.72z M116.56,60.59v-11.8h-12.22v11.8H116.56L116.56,60.59z M116.56,42.47v-11.8 h-12.22v11.8H116.56L116.56,42.47z M116.56,24.35v-11.8h-12.22v11.8H116.56L116.56,24.35z M18.54,96.84v-11.8H6.32v11.8H18.54 L18.54,96.84z M18.54,78.72v-11.8H6.32v11.8H18.54L18.54,78.72z M18.54,60.59v-11.8H6.32v11.8H18.54L18.54,60.59z M18.54,42.47 v-11.8H6.32v11.8H18.54L18.54,42.47z M18.54,24.35v-11.8H6.32v11.8H18.54L18.54,24.35z"/></g></svg>
          </div>
          <div *ngIf="type.label=='Image'">              
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 122.88 110.35" style="enable-background:new 0 0 122.88 110.35" xml:space="preserve"><g><path d="M6.32,0v6.22h12.22V0h6.32v14.88h73.15V0h6.32v6.22h12.22V0h6.32v110.35h-6.32v-7.19h-12.22v7.19h-6.32V94.79H24.87v15.56 h-6.32v-7.19H6.32v7.19H0V0H6.32L6.32,0z M44.67,35.77h33.53c0.76,0,1.45,0.31,1.95,0.81s0.81,1.19,0.81,1.95v33.3 c0,0.76-0.31,1.45-0.81,1.95c-0.5,0.5-1.19,0.81-1.95,0.81H44.67c-0.76,0-1.45-0.31-1.95-0.81c-0.5-0.5-0.81-1.19-0.81-1.95v-33.3 c0-0.76,0.31-1.45,0.81-1.95C43.23,36.08,43.92,35.77,44.67,35.77L44.67,35.77L44.67,35.77z M44.81,63.83l11.25-10.77 c0.45-0.42,1.16-0.37,1.56,0.08c0.01,0.01,0.01,0.02,0.02,0.02l10,11.83l1.53-9.41c0.1-0.6,0.67-1.02,1.27-0.92 c0.23,0.03,0.44,0.15,0.6,0.3l7.01,7.37V39.19c0-0.15-0.06-0.28-0.16-0.37c-0.09-0.09-0.23-0.16-0.37-0.16h-32.2 c-0.15,0-0.28,0.06-0.37,0.16c-0.09,0.09-0.16,0.23-0.16,0.37v24.64H44.81L44.81,63.83L44.81,63.83z M70.68,42.08 c1.1,0,2.11,0.45,2.83,1.17c0.73,0.73,1.17,1.73,1.17,2.83c0,1.1-0.45,2.11-1.17,2.83c-0.73,0.73-1.73,1.17-2.83,1.17 c-1.1,0-2.11-0.45-2.83-1.17c-0.73-0.73-1.17-1.73-1.17-2.83c0-1.1,0.45-2.11,1.17-2.83C68.59,42.52,69.58,42.08,70.68,42.08 L70.68,42.08z M98.01,21.2H24.87v67.27h73.15V21.2L98.01,21.2z M116.56,96.84v-11.8h-12.22v11.8H116.56L116.56,96.84z M116.56,78.72v-11.8h-12.22v11.8H116.56L116.56,78.72z M116.56,60.59v-11.8h-12.22v11.8H116.56L116.56,60.59z M116.56,42.47v-11.8 h-12.22v11.8H116.56L116.56,42.47z M116.56,24.35v-11.8h-12.22v11.8H116.56L116.56,24.35z M18.54,96.84v-11.8H6.32v11.8H18.54 L18.54,96.84z M18.54,78.72v-11.8H6.32v11.8H18.54L18.54,78.72z M18.54,60.59v-11.8H6.32v11.8H18.54L18.54,60.59z M18.54,42.47 v-11.8H6.32v11.8H18.54L18.54,42.47z M18.54,24.35v-11.8H6.32v11.8H18.54L18.54,24.35z"/></g></svg>
          </div>
          <div *ngIf="type.label=='Video'">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 122.88 110.35" style="enable-background:new 0 0 122.88 110.35" xml:space="preserve"><style type="text/css">.st0{fill-rule:evenodd;clip-rule:evenodd;}</style><g><path class="st0" d="M61.44,37.35c9.84,0,17.82,7.98,17.82,17.82c0,9.84-7.98,17.82-17.82,17.82c-9.84,0-17.82-7.98-17.82-17.82 C43.62,45.33,51.6,37.35,61.44,37.35L61.44,37.35z M6.32,0v6.22h12.22V0h6.32v14.88h73.15V0h6.32v6.22h12.22V0h6.32v110.35h-6.32 v-7.19h-12.22v7.19h-6.32V94.79H24.87v15.56h-6.32v-7.19H6.32v7.19H0V0H6.32L6.32,0z M98.01,21.2H24.87v67.27h73.15V21.2 L98.01,21.2z M116.56,96.84v-11.8h-12.22v11.8H116.56L116.56,96.84z M116.56,78.72v-11.8h-12.22v11.8H116.56L116.56,78.72z M116.56,60.59v-11.8h-12.22v11.8H116.56L116.56,60.59z M116.56,42.47v-11.8h-12.22v11.8H116.56L116.56,42.47z M116.56,24.35v-11.8 h-12.22v11.8H116.56L116.56,24.35z M18.54,96.84v-11.8H6.32v11.8H18.54L18.54,96.84z M18.54,78.72v-11.8H6.32v11.8H18.54 L18.54,78.72z M18.54,60.59v-11.8H6.32v11.8H18.54L18.54,60.59z M18.54,42.47v-11.8H6.32v11.8H18.54L18.54,42.47z M18.54,24.35 v-11.8H6.32v11.8H18.54L18.54,24.35z M70.35,55.16l-14.03,9.42v-18.7L70.35,55.16L70.35,55.16z"/></g></svg>
          </div>
        </label>
      </div>
    </div>
  </div>
  <div class="child" *ngIf="Eap.id && Eap.type!='Link'">
    <img  *ngIf="Eap.type=='Image'" [src]="imageUrl" style="width: 150px" />
    <!-- {{imageUrl.name.split('.').pop()}} -->
    <audio controls *ngIf="Eap.type=='Audio'">
      <source [src]="imageUrl" type="audio/ogg">
      <source [src]="imageUrl" type="audio/mpeg">
    </audio>
    <video width="320" height="240" controls *ngIf="Eap.type=='Video'">
      <source src="movie.mp4" type="video/mp4">
      <source src="movie.ogg" type="video/ogg">
    </video>
  </div>
</div>
<div class="container" *ngIf="Eap.id && Eap.type!='Link'">
  <div class="child">
      <input
        type="file"
        accept="*.*"
        (change)="handleFileInput($event.target.files)"
      />
  </div>
</div>
  <div class="container" *ngIf="Eap.id && Eap.type=='Link'">
    <div class="child">
      <span class="p-float-label">
        <input
          type="text"
          size="30"
          pInputText
          [(ngModel)]="Eap.link"
          (click)="$event.target.select()" required
          style="text-align: center;width: 100%;"
        />
        <label htmlFor="username">link</label>
      </span>
    </div>
  </div>
  <div class="container" *ngIf="Eap.id && Eap.type!='Link'">
    <div class="child">
      <!-- <p-fileUpload 
        mode="basic" 
        chooseLabel="Choose" 
        name="demo[]" 
        [url]="myUrl+'/api/api/uploadPriere.php'" 
        accept="image/*" maxFileSize="1000000" 
        (onUpload)="onUpload($event)">
      </p-fileUpload> -->

      <!-- <p-fileUpload name="demo[]" url="https://www.primefaces.org/cdn/api/upload.php" (onUpload)="onUpload($event)" [multiple]="true" accept="image/*" maxFileSize="1000000">
        <ng-template pTemplate="content">
            <ul *ngIf="uploadedFiles.length">
                <li *ngFor="let file of uploadedFiles">{{ file.name }} - {{ file.size }} bytes</li>
            </ul>
        </ng-template>
      </p-fileUpload> -->
        <div>
          <button
            style="float: right; margin-top: 10px; margin-right: 10px"
            type="button"
            pButton
            icon="pi pi-upload"
            (click)="onUpload()"
            label="Charger le fichier "
          ></button>
        </div> 
    </div>
  </div>
  <p-footer>
    <button
    *ngIf="Eap.id"
      type="button"
      pButton
      class="ui-button-danger"
      icon="pi pi-times"
      (click)="delete()"
      label="Supprimer"
    ></button>
    <button
      *ngIf="Eap.idType && Eap.titre && Eap.designation"
      type="button"
      pButton
      icon="pi pi-save"
      (click)="save()"
      label="Sauvegarder"
    ></button>
      <button
        type="button"
        pButton
        icon="pi pi-sign-out"
        (click)="displayDialog=false"
        label="Fermer"
      ></button>
  </p-footer>
</p-dialog>

