import { Component, OnInit } from '@angular/core';
import { DataService } from "../data.service";
import { environment } from "../../environments/environment";
import { Menu } from '../model/menu';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import {Message,MessageService} from 'primeng/api';
import {ExcelService} from '../excel.service';

@Component({
  selector: 'app-parametre-user',
  templateUrl: './parametre-user.component.html',
  styleUrls: ['./parametre-user.component.scss'],
  providers: [MessageService],
})
export class ParametreUserComponent implements OnInit {
  myUrl           : string = `${environment.urlApi}`;
  imageUrl        : string = this.myUrl + "/api/api/uploads/default.png";
  fileToUpload    : File = null;
  Donnee          : Menu = {};
  Donnees         : Menu[];
  tableDonnees    : Menu[];
  displayDialog   : boolean;
  blockedDocument : boolean = false;
  constructor(
    
    private router:Router,
    private data: DataService,
    public AuthenticationService: AuthenticationService,
    private messageService: MessageService,
    private excelService:ExcelService
  ) { }

  ngOnInit(): void {
    this.donnee();
  }
  donnee() {
    this.Donnee.action = "Select";
    this.data
      .menu(this.Donnee)
      .toPromise()
      .then((x: Menu[]) => (this.Donnees = x,this.tableDonnees = x));
  }

  filter(data, colone) {
    // console.log(data + colone);
    let recherche;
    if (colone=="id"){
       recherche = this.Donnees.filter((x) => x.id.toString().includes(data));
    }else{
      recherche = this.Donnees.filter(
      (x) =>
        x[colone].toLocaleLowerCase().indexOf(data.toLocaleLowerCase()) > -1
    ); 
    }
    this.tableDonnees = recherche;  
  }
  exportAsXLSX():void {
    this.excelService.exportAsExcelFile(this.tableDonnees, 'Table');
 }

  showDialogToAdd() {
    this.Donnee = {};
    this.displayDialog = true;
  }

  onRowSelect(event) {
    this.blockedDocument= false;
		this.displayDialog  = true;
    this.Donnee         = this.cloneDonnee(event.data);
	}
  cloneDonnee(c: Menu): Menu {
		let Donnee = {};
		for (let prop in c) {
			Donnee[prop] = c[prop];
		}
		return Donnee;
	}
  save() {    
    if (this.Donnee.id ) {
      // alert('update');
      this.blockedDocument    = true;
      this.Donnee.action      = "Update";
      console.log(this.Donnee);
      this.data
        .menu(this.Donnee)
        .toPromise()
        .then((res: { message: string }) => {
          if (res.message == "Opération de modification réuissie") {
            this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
            this.blockedDocument=false;
            this.displayDialog = false;
            this.donnee();            
          } else {
            alert("Nonnnn");
          }
        });
    } else {
      if (this.Donnee.idMenu || this.Donnee.label || this.Donnee.routerLink || this.Donnee.images ) {
        this.blockedDocument      = true;
        this.Donnee.action        = "Insert";
        this.data
          .menu(this.Donnee)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.donnee();              
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.blockedDocument=false;
              this.displayDialog = false;              
            } else {
              alert(res.message);
              this.displayDialog = false;
            }
          });
      } else {
        alert("Vous-avez oublier des champs!!!");
      }
    }
  }
  delete() {
    if (confirm("êtes vous sure?")) {
      this.Donnee.action = "Delete";
      this.data
        .menu(this.Donnee)
        .toPromise()
        .then((res: { message: string }) => {
          if ((res.message = "Opération de supression réuissie")) {
            this.messageService.add({severity:'warn', summary: 'Supression', detail: res.message});
            this.donnee();
            this.displayDialog = false;
          } else {
            alert("Nonnnn");
          }
        });
    }
  }
}