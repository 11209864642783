import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../data.service';
import { HttpClient } from '@angular/common/http';
import { MessageService, SortEvent } from 'primeng/api';
import { Eap } from '../model/eap';
import { AuthenticationService } from '../authentication.service';
import { User } from '../model/user';

@Component({
  selector: 'app-khatmaNew',
  templateUrl: './khatmaNew.component.html',
  styleUrls: ['./khatmaNew.component.scss'],  
  providers: [MessageService]
})
export class KhatmaNewComponent implements OnInit {

  Eap             : Eap = {};
  Eaps            : Eap[];
  khatamat        : Eap[];
  selectedValues  : string[] = [];  
  tablePlaning    : any[] = [];
  Donnee            : User = {};
  Donnees           : User[];
  Groupe            : Eap = {};
  Groupes           : Eap[];
  blockedDocument : boolean;
  dataTable       : string;
  innerWidth      : number;
  innerHeight     : number;
  dateSituation   : Date;
  dateSit         : string;
  idHizb          : string;
  checked         : boolean;
  elementToClipBoard : string;
  constructor(
    private data: DataService,     
    public AuthenticationService: AuthenticationService,
    private http: HttpClient,
    private messageService: MessageService,
    private router: Router,
    ) { }

  ngOnInit(): void {
    this.dateSit = new Date().toISOString().split("T")[0];;
    // this.dateSit.setDate(this.dateSit.getDate() + 1);
    
    this.groupe();
  }
  generatePlaning(){
    var req="INSERT INTO khatamatnext (date,hizb,idUser) VALUES ";
    var s = [
    {date:'2023-10-11',hizb:1,idUser:21},
    {date:'2023-10-11',hizb:3,idUser:14},
    {date:'2023-10-11',hizb:5,idUser:18},
    {date:'2023-10-11',hizb:7,idUser:25},
    {date:'2023-10-11',hizb:9,idUser:2},
    {date:'2023-10-11',hizb:11,idUser:19},
    {date:'2023-10-11',hizb:13,idUser:13},
    {date:'2023-10-11',hizb:15,idUser:12},
    {date:'2023-10-11',hizb:17,idUser:27},
    {date:'2023-10-11',hizb:19,idUser:8},
    {date:'2023-10-11',hizb:21,idUser:30},
    {date:'2023-10-11',hizb:23,idUser:5},
    {date:'2023-10-11',hizb:25,idUser:17},
    {date:'2023-10-11',hizb:27,idUser:11},
    {date:'2023-10-11',hizb:29,idUser:28},
    {date:'2023-10-11',hizb:31,idUser:16},
    {date:'2023-10-11',hizb:33,idUser:7},
    {date:'2023-10-11',hizb:35,idUser:26},
    {date:'2023-10-11',hizb:37,idUser:4},
    {date:'2023-10-11',hizb:39,idUser:20},
    {date:'2023-10-11',hizb:41,idUser:6},
    {date:'2023-10-11',hizb:43,idUser:24},
    {date:'2023-10-11',hizb:45,idUser:10},
    {date:'2023-10-11',hizb:47,idUser:29},
    {date:'2023-10-11',hizb:49,idUser:3},
    {date:'2023-10-11',hizb:51,idUser:1},
    {date:'2023-10-11',hizb:53,idUser:22},
    {date:'2023-10-11',hizb:55,idUser:9},
    {date:'2023-10-11',hizb:57,idUser:23},
    {date:'2023-10-11',hizb:59,idUser:15}];
    s.forEach((row,index)=>{
      this.dateSituation = new Date(row.date);
      var datee=this.dateSituation.getFullYear()+ "-" +  (this.dateSituation.getMonth()+ 1)+ "-" + this.dateSituation.getDate();
      var i=1;
      var j=row.hizb;
      for ( i = 1; i < 180; i += 2) {
        if(j>59){
          // i=1;
          j=1;
        }
        req+="('"+datee+"','"+j+"',"+row.idUser+"),";
        j=j+2;
        this.dateSituation.setDate(this.dateSituation.getDate() + 1);
        var datee=this.dateSituation.getFullYear()+ "-" +  (this.dateSituation.getMonth()+ 1)+ "-" + this.dateSituation.getDate();
      }      
    });
  }
  groupe(){
    this.Groupe.action = "Select";
    this.dataTable="groupe";
    this.data
      .donnee(this.dataTable,this.Groupe)
      .toPromise()
      .then((x: Eap[]) => (this.Groupes = x,this.donnees()));   
  }
  donnees() {
    this.dataTable    = "khatamatnow";
    this.Eap.action   = "SelectGroupe";
    this.Eap.dateSit  = this.dateSit;
    this.data
      .donnee(this.dataTable,this.Eap)
      .toPromise()
      .then((x: Eap[]) =>(this.Eaps = x))
  }
construireKhatmat(){
  // console.log(this.Eaps);
  var data= this.Eaps.filter(x => x.groupeTeam == this.Donnee.groupeTeam);
  // console.log(data);
  var groupe=this.Groupes.find(x => x.id == this.Donnee.groupeTeam).designation;
  const months = ["ینایر", "فبرایر", "مارس", "أبريل", "مای", "یونیو", "یولیوز", "غشت", "شتنبر", "أكتوبر", "نونبر", "دجنبر"];
  var nomPrenom="";
  var dateSit = new Date(this.dateSit);
  this.elementToClipBoard="🌻 السلام عليكم ورحمة الله وبركاته 🌻\n🌻"+ groupe +"🌻\nإعلان عن جدول الختمة لشهر💧"+dateSit.getDate()+"💧"+months[dateSit.getMonth()]+"🌹"+dateSit.getFullYear()+"🥀\n{قال رسول الله صلى الله عليه وسلم الماهر بالقرآن مع السفرة الكرام البررة}\n•┈┈•◈◉❀❒🌻❒❀◉◈•┈┈•\n🍯ختمة بتلاوة ورش 🍯\n*💛 المعلمة فاطمة مومن : *  اسماء الطاليبات\n";
  let liste =[];
  data.forEach((row,index)=>{
    if(row.dateSituation){
      const date1: Date=new Date(row.dateSituation);
      const date2:Date=new Date(this.dateSit);
      const differenceInMilliseconds: number =Math.abs(date2.getTime()-date1.getTime());
      var diff:number =Math.ceil(differenceInMilliseconds/(1000*60*60*24));
      nomPrenom=row.nom+' '+row.prenom;
      var hizb='';
      var hizbe=0;
      if(row.rythme=='2'){        
        if((row.hizb+1+diff*2)>60){
          if((row.hizb+diff*2)%60>0){
            hizb=((row.hizb+diff*2)%60+1)+'/'+((row.hizb+diff*2)%60);
            hizbe=(row.hizb+diff*2)%60;
          }else{
            hizb='59/60';
            hizbe=60;
          }
        }else{
          hizb=(row.hizb+1+diff*2)+'/'+(row.hizb+diff*2);
          hizbe=row.hizb+diff*2;
        }
      }else{
        if((row.hizb+diff)>60){
          if((row.hizb+diff)%60>0){
            hizb=((row.hizb+diff)%60)+'';
            hizbe=(row.hizb+diff)%60;
          }else{
            hizb='60';
            hizbe=60;
          }          
        }else{
          hizb=(row.hizb+diff)+'';
        }
      }
      liste.push({pre:"🍯*"+row.code+"*"+nomPrenom+" "+hizb+"🌹\n",hizb:hizbe});
    }     
    // data = JSON.parse(JSON.stringify(data.sort((p1, p2) => (p1.hizb > p2.hizb) ? 1 : (p1.hizb < p2.hizb) ? -1 : 0)));
  
    // console.log(this.elementToClipBoard);
    });
    liste = JSON.parse(JSON.stringify(liste.sort((p1, p2) => (p1.hizb > p2.hizb) ? 1 : (p1.hizb < p2.hizb) ? -1 : 0)));
    liste.forEach((row,index)=>{
      this.elementToClipBoard+=row.pre;
    });
  }
  copyInputMessage(inputElement){
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }
}
