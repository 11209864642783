import { Component, OnInit } from "@angular/core";
import { DataService } from "../data.service";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import {Message,MessageService} from 'primeng/api';
import { Router } from '@angular/router';
import { AuthenticationService } from "src/app/authentication.service";
import { User } from "../model/user";
import { Eap } from "../model/eap";

@Component({
  selector: 'app-gestionkhatamat',
  templateUrl: './gestionkhatamat.component.html',
  styleUrls: ['./gestionkhatamat.component.scss'],
  providers: [MessageService],
})
export class GestionkhatamatComponent implements OnInit {
  myUrl             : string = `${environment.urlApi}`;
  imageUrl          : string = this.myUrl + "/api/api/uploads/default.png";
  fileToUpload      : File = null;
  Donnee            : User = {};
  Donnees           : User[];
  Groupe            : Eap = {};
  Groupes           : Eap[];
  Ahzabs            : Eap[];
  selectedDonnee    : User;
  displayDialog     : boolean;
  blockedDocument   : boolean;
  ablee             : boolean;
  selectedOption    : string;
  dataTable         : string;
  groupes           : any;
  dateSituation   : string;
  selectedFile = null;
  stateOptions: any[] = [{label: 'Off', value: 0}, {label: 'On', value: 1}];
  constructor(
    private data: DataService, private http: HttpClient,
    private messageService: MessageService,
    public AuthenticationService: AuthenticationService,
    private router: Router) {}
  ngOnInit() {
    this.dateSituation= new Date().toISOString().split("T")[0];
    this.dataTable = "khatamatnow";
    this.donnee();
    this.groupes = [
			// { label: 'Administrateurs', value: 'Administrateurs' },
			{ label: 'Administrateur', value: 'Administrateur' },
			{ label: 'Utilisateur', value: 'Utilisateur' },
		];
    this.blockedDocument=false;
    // this.Donnee.visible=1;
  }

  handleFileInput(file: FileList) {
    this.fileToUpload = file.item(0);
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    };
    reader.readAsDataURL(this.fileToUpload);
  }

  // onUpload() {
  //   // alert(this.ModePaiement.modePaiement);
  //   this.Donnee.LIBELLEARABE = this.myUrl + "/api/api/";
  //   this.data
  //     .posteFile(
  //       this.Donnee.id,
  //       this.Donnee.LIBELLE,
  //       this.Donnee.LIBELLEARABE,
  //       this.fileToUpload
  //     )
  //     .subscribe((data: { message: string; url: string }) => {
  //       // alert(data);
  //       // console.log(data);
  //       this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: data.message});
  //       this.donnee();
  //       this.displayDialog = false;
  //     });
  // }
  donnee() {
    this.Donnee.action = "Select";
    this.dataTable="khatamatnow";
    this.data
      .donnee(this.dataTable,this.Donnee)
      .toPromise()
      .then((x: User[]) => (this.Donnees = x));
  }

  save() {
    if (!this.Donnee.hizb || !this.dateSituation) {
      this.messageService.add({severity:'warn', summary: 'Sauvegarde', detail: 'فارغ '});
    } else {
      this.Donnee.action = "Update";
      this.dataTable="khatamatnow";
      this.Donnee.dateNow=this.dateSituation;
      this.data
        .donnee(this.dataTable,this.Donnee)
        .toPromise()
        .then((res: { message: string }) => {
          if (res.message == "Opération de modification réuissie") {
            this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
            this.displayDialog = false;
            this.donnee();
          } else {
            console.log(res);
            alert(res.message);
            alert("Nonnnn");
          }
        });
    }
  }
  delete() {
    if (confirm("êtes vous sure?")) {
      this.Donnee.dateNow = new Date().toISOString().split("T")[0]; 
      this.Donnee.action = "Delete";
      this.dataTable="user";
      this.data
        .donnee(this.dataTable,this.Donnee)
        .toPromise()
        .then((res: { message: string }) => {
          if ((res.message = "Opération de supression réuissie")) {
            this.messageService.add({severity:'warn', summary: 'Supression', detail: res.message});
            this.displayDialog = false;
            this.donnee();
          } else {
            alert("Nonnnn");
          }
        });
    }
  }
  actionLink(lien) {
    this.router.navigate(["/"+lien]);
  }
  close() {
    this.displayDialog = false;
    this.Donnee = {};
  }
  newDonnee: boolean;
  showDialogToAdd() {
    this.newDonnee = true;
    this.imageUrl = this.myUrl + "/API/api/uploads/default.png";
    this.Donnee = {};
    // this.Donnee.visible=1 ;
    this.displayDialog = true;
  }
  onRowSelect(event) {
    // console.log(event);
    this.newDonnee = false;
    var leet = this.myUrl + "/API/api/uploads/images/" + event.data.images;
    this.imageUrl = leet;
    // alert(leet);
    this.Donnee = this.cloneDonnee(event.data);
    // this.Donnee.dateNow
    this.displayDialog = true;
    console.log(this.Donnee);
    
  }
  cloneDonnee(c: User): User {
    let Donnee = {};
    for (let prop in c) {
      Donnee[prop] = c[prop];
    }
    return Donnee;
  }
}