import { Component,OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterStateSnapshot } from "@angular/router";
import { AuthenticationService } from './authentication.service';
import { environment } from '../environments/environment';
import {MenuItem} from 'primeng/api';
import { Menu } from './model/menu';
import { DataService } from './data.service';
import {barcode} from "../assets/js/jquery-barcode.min.js";
import { Eap } from './model/eap';
// import * as $ from 'jquery';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {
  myUrl: string = `${environment.urlApi}`;
  Menu              : Menu = {};
  Menus             : Menu[];  
  MenusParent       : Menu[];
  MenusDetail       : Menu[];
  MenusDetailFind   : Menu[];
  menuItem          : any;
  dateSituation     : string;
  Eap               : Eap = {};
  Eaps              : Eap[];
  dataTable         : string;
  constructor(
    public AuthenticationService: AuthenticationService,
    private data: DataService,
    private router: Router,
    private route: ActivatedRoute
  ) {}
  title = 'OH';
  items: MenuItem[];
  itemsUser: MenuItem[];
  ngOnInit() {
    this.dateSituation = new Date().toISOString().split("T")[0];
    // this.donnees();
    this.menu();
    this.itemsUser = [
      {
          label: ' مكتبة الصوتيات',
          icon: 'pi pi-fw pi-youtube',
          routerLink: "Accueil",
      },
      {
          label: '  الختمات',
          icon: 'pi pi-fw pi-home',
          routerLink: "khatma",
      },
      {
          label:"  الاحصائيات", 
          icon:"pi pi-fw pi-chart-line", 
          routerLink: "khatamat",
      },
      {
          label: '  الخروج',
          icon: 'pi pi-fw pi-power-off',
          routerLink: "/",
      }
    ];
  }
   
  donnees() {
    this.dataTable = "khatamat";
    this.Eap.action = "Select5jour";
    this.data
      .donnee(this.dataTable,this.Eap)
      .toPromise()
      .then((x: Eap[]) => (this.Eaps = x,console.log(x),this.searchNextHizb()));
  }
	searchNextHizb(){
    var code=9999;
    var codeHizb=9999;
    this.Eaps.forEach((row,index)=>{
        console.log(row.code+ ' '+ row.hizb);
      if(code==row.code){
        // console.log(row);
        codeHizb++;
        if (codeHizb==row.hizb) {
          
        }else{
          codeHizb++;
          // console.log(row.code +'ff '+codeHizb);
        }        
      }else{
        code=row.code;
        codeHizb=row.hizb;
        console.log(row.code +'ff '+codeHizb);
      }
      
    });    
  }
  menu() {
    this.Menu.action = "Select";
    this.data
      .menu(this.Menu)
      .toPromise()
      .then((x: Menu[]) => (this.Menus = x, this.menuParent()));
  }
  
  menuParent() {
    this.Menu.action = "SelectGroup";
    this.data
      .menu(this.Menu)
      .toPromise()
      .then((x: Menu[]) => (this.MenusParent = x,this.MenuDetail()));
  }
  
  MenuDetail(){
    this.Menu.action = "SelectDetail";
    this.data
      .menu(this.Menu)
      .toPromise()
      .then((x: Menu[]) => (this.MenusDetail = x,this.construireMenu()));
  }
  construireMenu(){
    var element ='[';
    var elementDetail ='';
    var i=0;
    var j=0;
    this.MenusParent.forEach((row ,index )=>{
      if(j>0){
        element+=','
      }
        element+='{"label":"'+row.label+'", "icon":"'+row.icon+'",  "routerLink": "'+row.routerLink+'"';
        // element+="{label:'"+row.label+"', type :'person', styleClass:'p-person',  expanded: true, data: {name:'"+row.label+"', avatar:'"+row.label+"'}";
        // elementDetail +=', children: [';
         elementDetail="";
         i=0;
        let recherche = this.MenusDetail.filter((x) =>row.id==x.idParent );
        this.MenusDetailFind = recherche;
        if(this.MenusDetailFind.length>0){
            this.MenusDetailFind.forEach((detail ,indexx )=>{
              if(i>0 ){
                elementDetail+=',';                
              }
              elementDetail+='{"label":"'+detail.label+'", "icon":"'+detail.icon+'", "routerLink": "'+detail.routerLink+'"}'; 
              i++;
           });
          if(elementDetail!=""){
            // element+=', children: ['+elementDetail+']},'
            element+= ',"items" : [ '+elementDetail+']}';
          }           
        }else{
          element+='}';
        }        

       j++;
      // this.data1=this.data3;
    }); 
    element+=']';
    // element='[{"label":"Parametre", "icon":"pi pi-fw  pi-fast-backward",  "routerLink": " "},{"label":"Achats", "icon":" ",  "routerLink": " "},{"label":"Factures", "icon":" ",  "routerLink": "facture","items" : [ {"label":"Facture", "icon":" ", "routerLink": " "},{"label":"Devis", "icon":" ", "routerLink": " "}]},{"label":"Ventes Magasin", "icon":" ",  "routerLink": " ","items" : [ {"label":"Vente Magasin", "icon":" ", "routerLink": " "}{"label":"Clients", "icon":" ", "routerLink": " "}{"label":"Articles / Clients", "icon":" ", "routerLink": " "}{"label":"Paiements Clients", "icon":" ", "routerLink": " "}{"label":"Avoir", "icon":" ", "routerLink": " "}]}] ';
    // console.log(element);
    this.items=JSON.parse(element);
    this.menuItem=JSON.stringify(this.items, undefined, 2);
  }
  ngOnDestroy() {
    localStorage.removeItem("currentUser");
  }
  logout() {
    this.AuthenticationService.logout();
    this.router.navigate(["/"]);
  }
  actionLink(lien) {
    this.router.navigate(["/"+lien]);
  }
}
