import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from "./login/login.component";
import { AuthGuard } from "./auth.guard";
import { AcceuilComponent } from './acceuil/acceuil.component';
import { ParametreUserComponent } from './parametre-user/parametre-user.component';
import { MenuComponent } from './menu/menu.component';
import { MenuUserComponent } from './menuUser/menuUser.component';
import { GestionuserComponent } from './gestionuser/gestionuser.component';
import { KhatmaComponent } from './khatma/khatma.component';
import { KhatamatComponent } from './khatamat/khatamat.component';
import { KhatmaNextComponent } from './khatmaNext/khatmaNext.component';
import { PriereComponent } from './priere/priere.component';
import { TypepriereComponent } from './typepriere/typepriere.component';
import { TyperegleComponent } from './typeregle/typeregle.component';
import { GroupeComponent } from './groupe/groupe.component';
import { GestionkhatamatComponent } from './gestionkhatamat/gestionkhatamat.component';
import { KhatmaNewComponent } from './khatmaNew/khatmaNew.component';
import { RegleComponent } from './regle/regle.component';
const routes: Routes = [
  {
    path: "",
    component: LoginComponent,
  },
  {
    path: "login",
    component: LoginComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "Accueil",
    component: AcceuilComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "GestionUser",
    component: GestionuserComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "GestionKhatamat",
    component: GestionkhatamatComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "Menu",
    component: MenuComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "MenuUser",
    component: MenuUserComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "khatma",
    component: KhatmaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "khatamat",
    component: KhatamatComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "khatamatNext",
    component: KhatmaNextComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "khatamatNew",
    component: KhatmaNewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "priere",
    component: PriereComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "regle",
    component: RegleComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "groupe",
    component: GroupeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "typePriere",
    component: TypepriereComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "typeRegle",
    component: TyperegleComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "Parametre",
    component: ParametreUserComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
