<p-toast [style]="{'min-width': '30%', width: '70%'}"></p-toast>
  <img style="
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;" width="100%" src="{{ this.myUrl }}/API/API/uploads/bg.png"/>

  <div class="login">
    <div class="grid p-fluid">
      <div class="col-4 md:col-4">
        <img style="top: -90px; height: 120px;" src="{{ this.myUrl }}/API/API/uploads/logo2.png">
      </div><br><br>
      <div class="col-4 md:col-4">
          <div class="p-inputgroup">
              <span class="p-inputgroup-addon" style="cursor:pointer;width: 20px;"><i class="pi pi-user"></i></span>
              <input 
                type="text" 
                pInputText 
                placeholder="المستخدم"
                [(ngModel)]="user.username"
                (keyup.enter)="login()">         
          </div>
      </div>
      <br>
      <div class="col-4 md:col-4">
        <div class="p-inputgroup">
              <span class="p-inputgroup-addon" (click)="showPassword()" style="cursor:pointer;width: 20px;">
                <i class="{{showHideClass}}"></i>
              </span>
              <input                 
                type="{{inputType}}"
                pInputText
                placeholder="الرقم السري"
                [(ngModel)]="user.password"
                (keyup.enter)="login()">         
          </div>
      </div>
    </div>
      <br> 
      <div class="ui-inputgroup ui-g-12">
        <button
          style="width: 100%;"
            pButton
            type="button"
            label="فتح الحســــــاب"
            class="ui-button-raised"
            (click)="login()"
          ></button>
      </div>    
  </div>