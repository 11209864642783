import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../data.service';
import { HttpClient } from '@angular/common/http';
import { MessageService, SortEvent } from 'primeng/api';
import { Eap } from '../model/eap';
import { AuthenticationService } from '../authentication.service';

@Component({
  selector: 'app-khatamat',
  templateUrl: './khatamat.component.html',
  styleUrls: ['./khatamat.component.scss'],  
  providers: [MessageService]
})
export class KhatamatComponent implements OnInit {

  Eap             : Eap = {};
  Eaps            : Eap[];
  khatamat        : Eap[];
  selectedValues  : string[] = [];
  Groupe            : Eap = {};
  Groupes           : Eap[];
  blockedDocument : boolean;
  dataTable       : string;
  innerWidth      : number;
  innerHeight     : number;
  dateSituation   : string;
  idHizb          : string;
  groupeTeam      : number;
  able            : boolean=false;
  constructor(
    private data: DataService,     
    public AuthenticationService: AuthenticationService,
    private http: HttpClient,
    private messageService: MessageService,
    private router: Router,
    ) { }

  ngOnInit(): void {
    this.dateSituation = new Date().toISOString().split("T")[0];
    if(this.AuthenticationService.currentUserValue.groupe=='Administrateur' || this.AuthenticationService.currentUserValue.groupe=='Administrateurs' ){
      this.able=true;
    }
    this.donnees();
    this.rechercher();
    this.innerWidth = window.innerWidth;
    this.innerHeight = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight)-120;
  }
  @HostListener('window:resize')
  onResize() {this.updatePageDimensions();}
  updatePageDimensions() {
    this.innerHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    this.innerHeight =this.innerHeight-120;
    this.innerWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    this.innerWidth =this.innerWidth-10;
  }
  generateidHizb(selectedValues){
    // console.log(selectedValues);
    this.idHizb="";
    if (selectedValues.length>0) {
      for (let data of this.selectedValues) {
        this.idHizb += "("+data+",'"+ this.dateSituation+"',"+this.AuthenticationService.currentUserValue.id+"),";
      }
      this.idHizb += ',';
      this.idHizb=this.idHizb.replace(",,", "");
      // console.log(this.idHizb);      
    }    
  }
  donnees() {
    this.dataTable = "ahzab";
    this.Eap.action = "Select";
    this.data
      .donnee(this.dataTable,this.Eap)
      .toPromise()
      .then((x: Eap[]) => (this.Eaps = x,this.groupe()));
  }
  groupe(){
    this.Groupe.action = "Select";
    this.dataTable="groupe";
    this.data
      .donnee(this.dataTable,this.Groupe)
      .toPromise()
      .then((x: Eap[]) => (this.Groupes = x));   
  }
  rechercher(){
    this.dataTable = "khatamat";
    this.Eap.action = "Select";
    this.Eap.idUser = this.AuthenticationService.currentUserValue.id;
    if(this.groupeTeam){
      this.Eap.groupe = this.groupeTeam;
    }else{
      this.Eap.groupe = this.AuthenticationService.currentUserValue.groupeTeam;
    }
    
    this.data
      .donnee(this.dataTable,this.Eap)
      .toPromise()
      .then((x: Eap[]) => (this.khatamat = x, this.updateAhzab()));  
  }
  
  updateAhzab(){
    this.selectedValues=[]; 
      if(this.Eaps){
      this.Eaps.forEach((row,index)=>{
        // console.log(this.khatamat);      
        let updateItem = this.khatamat.filter(item=>item.hizb==row.id && item.dateSituation==this.dateSituation );
        if (updateItem===undefined){
          // this.tableVentess[index].avance = 0;
          // this.tableVentess[index].reste = this.tableVentess[index].total;  
          this.Eaps[index].cheked=false;       
        }else{
          // console.log(updateItem);
          this.Eaps[index].nom='';
          var nom='(';
          updateItem.forEach((rowHizb)=>{
            this.selectedValues.push(rowHizb.hizb.toString())
            // this.Eaps[index].cheked=true;
            nom+=rowHizb.code+' '+rowHizb.label+'-';
            // this.Eaps[index].nom+=' - ';
            // this.Eaps[index].code+=rowHizb.code;
          });
          nom+='-)';
          nom=nom.replace("--", "");
          nom=nom.replace("(-)", "");
          this.Eaps[index].nom=nom;
          // console.log(this.Eaps[index].nom);
          
            // this.Eaps[index].cheked=true;
            this.Eaps[index].user=updateItem;
            // this.Eaps[index].code=updateItem.code;
          // console.log(this.Eaps[index].nom);
          
            // this.tableVentess[index].avance = updateItem.montant;
            // this.tableVentess[index].reste = this.tableVentess[index].total-updateItem.montant;
        }
      });
   }
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        let value1 = data1[event.field];
        let value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null) result = -1;
        else if (value1 != null && value2 == null) result = 1;
        else if (value1 == null && value2 == null) result = 0;
        else if (typeof value1 === 'string' && typeof value2 === 'string') result = value1.localeCompare(value2);
        else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

        return event.order * result;
    });
}
}
