import { Component, OnInit } from "@angular/core";
import { DataService } from "../data.service";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Menu } from "../model/menu";
import {Message,MessageService} from 'primeng/api';
import { Router } from '@angular/router';

// import { ConfirmDialogModule } from "primeng/confirmdialog";
// import { ConfirmationService } from "primeng/api";
@Component({
  selector: "app-menu",
  templateUrl: "menu.component.html",
  styleUrls: ["menu.component.scss"],
  providers: [MessageService],
})
export class MenuComponent implements OnInit {
  myUrl: string = `${environment.urlApi}`;
  imageUrl: string = this.myUrl + "/api/api/uploads/default.png";
  fileToUpload: File = null;
  Menu: Menu = {};
  Menus: Menu[];  
  selectedMenu: Menu;
  displayDialog: boolean;
  blockedDocument: boolean;
  selectedOption: string;
  selectedFile = null;
  constructor(
    private data: DataService, private http: HttpClient,
    private messageService: MessageService,
    private router: Router) {}
  ngOnInit() {
    this.menu();
  }

  handleFileInput(file: FileList) {
    this.fileToUpload = file.item(0);
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    };
    reader.readAsDataURL(this.fileToUpload);
  }

  onUpload() {
    // alert(this.Menu.menu);
    this.Menu.chemin = this.myUrl + "/api/api/";
    this.data
      .posteFileMenu(
        this.Menu.id,
        this.Menu.label,
        this.Menu.chemin,
        this.fileToUpload
      )
      .subscribe((data: { message: string; url: string }) => {
        // alert(data);
        // console.log(data);
        alert(data.message);
        this.menu();
        this.displayDialog = false;
      });
  }
  menu() {
    this.Menu.action = "Select";
    this.data
      .menu(this.Menu)
      .toPromise()
      .then((x: Menu[]) => (this.Menus = x));
  }

  save() {
    // console.log( this.Menu);
    if (this.Menu.idMenu == null || this.Menu.icon == null || this.Menu.data == null ||this.Menu.label == null || this.Menu.labelAr == null || this.Menu.routerLink == null) {
      alert("Il manque quelque chose !!!");
    } else {
      if (this.Menu.id) {
        this.blockedDocument=true;
        this.Menu.action = "Update";
        this.data
          .menu(this.Menu)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.displayDialog = false;
              this.blockedDocument=false;
              this.menu();
            } else {
              console.log(res);
              alert(res.message);
              alert("Nonnnn");
            }
          });
      } else {
        this.blockedDocument=true;
        this.Menu.action = "Insert";
        this.data
          .menu(this.Menu)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.displayDialog = false;
              this.blockedDocument=false;
              this.menu();
            } else {
              alert(res.message);
              this.displayDialog = false;
            }
          });
      }
    }
  }
  delete() {
    if (confirm("êtes vous sure?")) {
      this.Menu.action = "Delete";
      this.data
        .menu(this.Menu)
        .toPromise()
        .then((res: { message: string }) => {
          if ((res.message = "Opération de supression réuissie")) {
            // alert(res.message);
            this.messageService.add({severity:'warn', summary: 'Sauvegarde', detail: res.message});
            this.displayDialog = false;
            this.menu();
          } else {
            alert("Nonnnn");
          }
        });
    }
  }
  actionLink(lien) {
    this.router.navigate(["/"+lien]);
  }
  close() {
    this.displayDialog = false;
    this.Menu = {};
  }
  newDonnee: boolean;
  showDialogToAdd() {
    this.newDonnee = true;
    this.imageUrl = this.myUrl + "/API/api/uploads/default.png";
    this.Menu = {};
    this.displayDialog = true;
  }
  onRowSelect(event) {
    console.log(event);
    this.newDonnee = false;
    var leet = this.myUrl + "/API/api/uploads/" + event.data.images;
    this.imageUrl = leet;
    // alert(leet);
    this.Menu = this.cloneDonnee(event.data);
    this.displayDialog = true;
    console.log(this.Menu);
  }
  cloneDonnee(c: Menu): Menu {
    let Menu = {};
    for (let prop in c) {
      Menu[prop] = c[prop];
    }
    return Menu;
  }
}
