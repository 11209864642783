<p-toast [style]="{width: '30%'}"></p-toast>
<p-blockUI [blocked]="blockedDocument">
  <div class="centered">
      <i class="pi pi-spin pi-spinner" style="font-size: 4rem"></i>
     المرجوا الانتظار ...
  </div>
</p-blockUI>

<div class="area">
  <p-card [style]="{ 'margin-left': '30px' }">
    <h1 class="ribbon" style="color: white;  text-shadow: 2px 2px 4px #000000;background: rgb(123, 199, 146);">
      Gestion des Menussss
      <button
        style="float: right; margin-top: 10px; margin-right: 10px"
        menu="button"
        pButton
        icon="pi pi-plus"
        iconPos="right"
        (click)="showDialogToAdd()"
        label="Ajouter une nouvelle famille "
      ></button>
    </h1>
    <br />
    <!-- <div (mouseover)="changeText = true" (mouseout)="changeText = false">
      ttttt
    </div>
    <span *ngIf="!changeText">Hide </span>
    <span *ngIf="changeText"
      >Show
      <img
        src="{{ this.myUrl }}/API/api/uploads/{{ changeText }}"
        height="50px"
        width="80px"
      />
    </span> -->
    <!-- {{User | json}} -->
    <p-table
      #dt1
      [value]="Menus"
      selectionMode="single"
      [(selection)]="selectedMenu"
      (onRowSelect)="onRowSelect($event)"
      [paginator]="true"
      [rows]="10"
    >
      <ng-template pTemplate="header">
        <tr>
          <th  [pSortableColumn]="'id'"><p-sortIcon [field]="'id'"></p-sortIcon> id</th>
          <th  [pSortableColumn]="'classement'"><p-sortIcon [field]="'classement'"></p-sortIcon> classement</th>
          <th  [pSortableColumn]="'data'"><p-sortIcon [field]="'data'"></p-sortIcon> data</th>
          <th  [pSortableColumn]="'idParent'"><p-sortIcon [field]="'idParent'"></p-sortIcon>idParent</th>
          <th  [pSortableColumn]="'idMenu'"><p-sortIcon [field]="'idMenu'"></p-sortIcon>idMenu</th>
          <th  [pSortableColumn]="'label'"><p-sortIcon [field]="'label'"></p-sortIcon>label</th>
          <th  [pSortableColumn]="'labelAr'"><p-sortIcon [field]="'labelAr'"></p-sortIcon>الاسم</th>
          <th  [pSortableColumn]="'icon'"><p-sortIcon [field]="'icon'"></p-sortIcon>icon</th>
          <th  [pSortableColumn]="'routerLink'"><p-sortIcon [field]="'routerLink'"></p-sortIcon>routerLink</th>
          <th  [pSortableColumn]="'leaf'"><p-sortIcon [field]="'leaf'"></p-sortIcon>leaf</th>
          <th>Images</th>
        </tr>
        <tr>
          <th>
            <input style="width: 100%;" pInputText type="text" (input)="dt1.filter($event.target.value, 'id', 'contains')" [value]="dt1.filters['id']?.value" placeholder="ID" class="p-column-filter">
          </th>
          <th>
            <input style="width: 100%;" pInputText type="text" (input)="dt1.filter($event.target.value, 'classement', 'contains')" [value]="dt1.filters['classement']?.value" placeholder="Classement" class="p-column-filter">
          </th>
          <th>
            <input style="width: 100%;" pInputText type="text" (input)="dt1.filter($event.target.value, 'data', 'contains')" [value]="dt1.filters['data']?.value" placeholder="Data" class="p-column-filter">
          </th>
          <th>
            <input style="width: 100%;" pInputText type="text" (input)="dt1.filter($event.target.value, 'idParent', 'contains')" [value]="dt1.filters['idParent']?.value" placeholder="idParent" class="p-column-filter">
          </th>
          <th>
            <input style="width: 100%;" pInputText type="text" (input)="dt1.filter($event.target.value, 'idMenu', 'contains')" [value]="dt1.filters['idMenu']?.value" placeholder="idMenu" class="p-column-filter">
          </th>
          <th>
            <input style="width: 100%;" pInputText type="text" (input)="dt1.filter($event.target.value, 'label', 'contains')" [value]="dt1.filters['label']?.value" placeholder="label" class="p-column-filter">
          </th>
          <th>
            <input style="width: 100%;" pInputText type="text" (input)="dt1.filter($event.target.value, 'labelAr', 'contains')" [value]="dt1.filters['labelAr']?.value" placeholder="labelAr" class="p-column-filter">
          </th>
          <th>
            <input style="width: 100%;" pInputText type="text" (input)="dt1.filter($event.target.value, 'icon', 'contains')" [value]="dt1.filters['icon']?.value" placeholder="icon" class="p-column-filter">
          </th>
          <th>
            <input style="width: 100%;" pInputText type="text" (input)="dt1.filter($event.target.value, 'routerLink', 'contains')" [value]="dt1.filters['routerLink']?.value" placeholder="routerLink" class="p-column-filter">
          </th>
          <th>
            <input style="width: 100%;" pInputText type="text" (input)="dt1.filter($event.target.value, 'leaf', 'contains')" [value]="dt1.filters['leaf']?.value" placeholder="leaf" class="p-column-filter">
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-Menu>
        <tr
          [pSelectableRow]="Menu"
          (mouseover)="changeText = Menu.images"
          (mouseout)="changeText = false"
        >
          <td>{{ Menu.id }}</td>
          <td>{{ Menu.classement }}</td>
          <td>{{ Menu.data }}</td>
          <td>{{ Menu.idParent }}</td>
          <td>{{ Menu.idMenu }}</td>
          <td>{{ Menu.label }}</td>
          <td dir="rtl">{{ Menu.labelAr }}</td>
          <td>{{ Menu.icon }}</td>
          <td>{{ Menu.routerLink }}</td>
          <td>{{ Menu.leaf }}</td>
          <td align="center">
            <img
              src="{{ this.myUrl }}/API/api/uploads/images/{{Menu.images}}"
              height="50px"
              width="80px"
            />
          </td>
        </tr>
      </ng-template>
    </p-table>
  </p-card>  
</div>

<p-dialog
  header="Gestion des Menus"
  [(visible)]="displayDialog"
  [focusOnShow]="false"
  [responsive]="true"
  showEffect="fade"
  [modal]="true"
  [style]="{ width: '800px' }"
>
<div style="float: left;">
  <div class="ui-inputgroup">
    <span class="ui-inputgroup-addon">
      <i style="line-height: 1.25; width: 12em">classement </i>
    </span>
    <input
      type="text"
      pInputText
      placeholder="classement"
      [(ngModel)]="Menu.classement"
      style="width: 18em"
    />
  </div>
  <div class="ui-inputgroup">
    <span class="ui-inputgroup-addon">
      <i style="line-height: 1.25; width: 12em">idMenu </i>
    </span>
    <input
      type="text"
      pInputText
      placeholder="idMenu"
      [(ngModel)]="Menu.idMenu"
      style="width: 18em"
    />
  </div>
  <div class="ui-inputgroup">
    <span class="ui-inputgroup-addon">
      <i style="line-height: 1.25; width: 12em">idParent </i>
    </span>
    <input
      type="text"
      pInputText
      placeholder="idParent"
      [(ngModel)]="Menu.idParent"
      style="width: 18em"
    />
  </div>
  <div class="ui-inputgroup">
    <span class="ui-inputgroup-addon">
      <i style="line-height: 1.25; width: 12em">data </i>
    </span>
    <input
      type="text"
      pInputText
      placeholder="data"
      [(ngModel)]="Menu.data"
      style="width: 18em"
    />
  </div>
  <div class="ui-inputgroup">
    <span class="ui-inputgroup-addon">
      <i style="line-height: 1.25; width: 12em">Label </i>
    </span>
    <input
      type="text"
      pInputText
      placeholder="Label"
      [(ngModel)]="Menu.label"
      style="width: 18em"
    />
  </div>
  <div class="ui-inputgroup">
    <span class="ui-inputgroup-addon">
      <i style="line-height: 1.25; width: 12em">الاسم </i>
    </span>
    <input
      type="text"
      pInputText
      placeholder="الاسم"
      [(ngModel)]="Menu.labelAr"
      style="width: 18em"
    />
  </div>
  <div class="ui-inputgroup">
    <span class="ui-inputgroup-addon">
      <i style="line-height: 1.25; width: 12em">ICON </i>
    </span>
    <input
      type="text"
      pInputText
      placeholder="ICON"
      [(ngModel)]="Menu.icon"
      style="width: 18em"
    />
  </div>
  <div class="ui-inputgroup">
    <span class="ui-inputgroup-addon">
      <i style="line-height: 1.25; width: 12em">routerLink </i>
    </span>
    <input
      type="text"
      pInputText
      placeholder="routerLink"
      [(ngModel)]="Menu.routerLink"
      style="width: 18em"
    />
  </div>
  <div class="ui-inputgroup">
    <span class="ui-inputgroup-addon">
      <i style="line-height: 1.25; width: 12em">leaf </i>
    </span>
    <input
      type="text"
      pInputText
      placeholder="leaf"
      [(ngModel)]="Menu.leaf"
      style="width: 18em"
    />
  </div>
</div>
<div style="height: 350px;"> 
  <p-card [style]="{ 'margin-left': '10px', 'text-align':'center' }" *ngIf="!newDonnee">
    <input
    [(ngModel)]="file"
      type="file"
      accept="image/*"
      (change)="handleFileInput($event.target.files)"
    />
    <br>
    <div>
      <button pButton type="button" (click)="onUpload()">Charger l'image</button>
    </div> 
    <img [src]="imageUrl" style="width: 300px" />      
  </p-card>
</div>
<!-- <div style="display: inline-flex; width: 150px;" *ngIf="!newDonnee">
  <img [src]="imageUrl" style="width: 350px" />
    <img 
    src="{{ this.myUrl }}/API/api/uploads/{{Menu.images}}.png" 
    style="width: 250px" />
    <input
      type="file"
      accept="image/*"
      (change)="handleFileInput($event.target.files)"
    />
    <button type="button" (click)="onUpload()">UpLoad</button>
</div> -->
  <p-footer>
    <div class="ui-dialog-buttonpane ui-helper-clearfix">
      <button
        style="float: right; margin-top: 10px; margin-right: 10px"
        menu="button"
        pButton
        icon="pi pi-sign-out"
        (click)="close()"
        label="Fermer"
      ></button>
      <button
        menu="button"
        style="float: left; margin-top: 10px; margin-right: 10px"
        pButton
        class="ui-button-danger"
        icon="pi pi-times"
        (click)="delete()"
        label="Supprimer"
      ></button>
      <button
        style="float: right; margin-top: 10px; margin-right: 10px"
        menu="button"
        pButton
        icon="pi pi-save"
        (click)="save()"
        label="Sauvegarder"
      ></button>
    </div>
  </p-footer>
</p-dialog>
