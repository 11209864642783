import { Component, OnInit } from "@angular/core";
import { DataService } from "../data.service";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import {MenuItem} from 'primeng/api';
import { Menu } from "../model/menu";
import {Message,MessageService} from 'primeng/api';
import { Router } from '@angular/router';
import { User } from "../model/user";

// import { ConfirmDialogModule } from "primeng/confirmdialog";
// import { ConfirmationService } from "primeng/api";
@Component({
  selector: "app-menuUser",
  templateUrl: "menuUser.component.html",
  styleUrls: ["menuUser.component.scss"],
  providers: [MessageService],
})
export class MenuUserComponent implements OnInit {
  myUrl: string = `${environment.urlApi}`;
  imageUrl: string = this.myUrl + "/api/api/uploads/default.png";
  fileToUpload      : File = null;
  items             : MenuItem[];
  itemsUser         : MenuItem[];
  Menu              : Menu = {};
  Menus             : Menu[];  
  MenusParent       : Menu[];
  MenusDetail       : Menu[];
  MenusDetailFind   : Menu[];
  selectedMenu      : Menu;
  User              : User = {};
  Users             : User[];
  menuUsers         : User[];
  displayDialog     : boolean;
  blockedDocument   : boolean;
  selectedOption    : string;
  menuItem          : any;
  menuUtilisateur   : any;
  dataTable         : string;
  selectedFile      = null;
  constructor(
    private data: DataService, 
    private http: HttpClient,
    private messageService: MessageService,
    private router: Router) {}
  ngOnInit() {
    this.menu();
    // this.menuParent();
    // this.menuUser();
    this.donnee();
  }
  donnee() {
    this.dataTable = "user";
    this.User.action = "Select";
    this.data
      .donnee(this.dataTable,this.User)
      .toPromise()
      .then((x: User[]) => (this.Users = x,this.menuUser()));
  }
  menuUser() {
    this.dataTable = "user";
    this.User.action = "SelectMenu";
    this.data
      .donnee(this.dataTable,this.User)
      .toPromise()
      .then((x: User[]) => (this.menuUsers = x));
  }
  handleFileInput(file: FileList) {
    this.fileToUpload = file.item(0);
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    };
    reader.readAsDataURL(this.fileToUpload);
  }

  onUpload() {
    // alert(this.Menu.menu);
    this.Menu.chemin = this.myUrl + "/api/api/";
    this.data
      .posteFileMenu(
        this.Menu.id,
        this.Menu.label,
        this.Menu.chemin,
        this.fileToUpload
      )
      .subscribe((data: { message: string; url: string }) => {
        // alert(data);
        // console.log(data);
        alert(data.message);
        this.menu();
        this.displayDialog = false;
      });
  }
  menu() {
    this.Menu.action = "Select";
    this.data
      .menu(this.Menu)
      .toPromise()
      .then((x: Menu[]) => (this.Menus = x, this.menuParent()));
  }
  
  menuParent() {
    this.Menu.action = "SelectGroup";
    this.data
      .menu(this.Menu)
      .toPromise()
      .then((x: Menu[]) => (this.MenusParent = x,this.MenuDetail()));
  }
  
  MenuDetail(){
    this.Menu.action = "SelectDetail";
    this.data
      .menu(this.Menu)
      .toPromise()
      .then((x: Menu[]) => (this.MenusDetail = x,this.construireMenu()));
  }
  construireMenu(){
    var element ='[';
    var elementDetail ='';
    var i=0;
    var j=0;
    this.MenusParent.forEach((row ,index )=>{
      if(j>0){
        element+=','
      }
        element+='{"label":"'+row.label+'", "icon":"'+row.icon+'",  "routerLink": "'+row.routerLink+'"';
        // element+="{label:'"+row.label+"', type :'person', styleClass:'p-person',  expanded: true, data: {name:'"+row.label+"', avatar:'"+row.label+"'}";
        // elementDetail +=', children: [';
         elementDetail="";
         i=0;
        let recherche = this.MenusDetail.filter((x) =>row.id==x.idParent );
        this.MenusDetailFind = recherche;
        if(this.MenusDetailFind.length>0){
            this.MenusDetailFind.forEach((detail ,indexx )=>{
              if(i>0 ){
                elementDetail+=',';                
              }
              elementDetail+='{"label":"'+detail.label+'", "icon":"'+detail.icon+'", "routerLink": "'+detail.routerLink+'"}'; 
              i++;
           });
          if(elementDetail!=""){
            // element+=', children: ['+elementDetail+']},'
            element+= ',"items" : [ '+elementDetail+']}';
          }           
        }else{
          element+='}';
        }        

       j++;
      // this.data1=this.data3;
    }); 
    element+=']';
    // element='[{"label":"Parametre", "icon":"pi pi-fw  pi-fast-backward",  "routerLink": " "},{"label":"Achats", "icon":" ",  "routerLink": " "},{"label":"Factures", "icon":" ",  "routerLink": "facture","items" : [ {"label":"Facture", "icon":" ", "routerLink": " "},{"label":"Devis", "icon":" ", "routerLink": " "}]},{"label":"Ventes Magasin", "icon":" ",  "routerLink": " ","items" : [ {"label":"Vente Magasin", "icon":" ", "routerLink": " "}{"label":"Clients", "icon":" ", "routerLink": " "}{"label":"Articles / Clients", "icon":" ", "routerLink": " "}{"label":"Paiements Clients", "icon":" ", "routerLink": " "}{"label":"Avoir", "icon":" ", "routerLink": " "}]}] ';
    // console.log(element);
    this.items=JSON.parse(element);
    this.menuItem=JSON.stringify(this.items, undefined, 2);
  }
  changeMenu(event){
    // console.log(event);
    this.itemsUser=JSON.parse(event);
    // this.User.designation=event;
  }
  findUser(idUser){
    let updateItem = this.menuUsers.find(item=> item.idUser===idUser);
    if(updateItem){       
      this.itemsUser =JSON.parse(updateItem.designation);
       this.menuUtilisateur=JSON.stringify(this.itemsUser, undefined, 2);
    }
  }
  save() {
    // console.log( this.Menu);
    if (this.User.idUser == null ) {
      alert("Il manque quelque chose !!!");
    } else {
      // if (this.User.id) {
        // console.log(this.User)
        this.blockedDocument=true;
        this.dataTable = "user";
        this.User.designation=JSON.stringify(this.itemsUser);
        this.User.action = "UpdateMenu";
        this.data
          .donnee(this.dataTable,this.User)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.displayDialog = false;
              this.blockedDocument=false;
              this.menu();
            } else {
              console.log(res);
              alert(res.message);
              alert("Nonnnn");
            }
          });
      // } else {
      //   this.blockedDocument=true;
      //   this.dataTable = "user";
      //   this.User.action = "Insert";
      //   this.data
      //   .donnee(this.User,this.dataTable)
      //     .toPromise()
      //     .then((res: { message: string }) => {
      //       if (res.message == "Opération de modification réuissie") {
      //         this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
      //         this.displayDialog = false;
      //         this.blockedDocument=false;
      //         this.menu();
      //       } else {
      //         alert(res.message);
      //         this.displayDialog = false;
      //       }
      //     });
      // }
    }
  }
  delete() {
    if (confirm("êtes vous sure?")) {
      this.Menu.action = "Delete";
      this.data
        .menu(this.Menu)
        .toPromise()
        .then((res: { message: string }) => {
          if ((res.message = "Opération de supression réuissie")) {
            // alert(res.message);
            this.messageService.add({severity:'warn', summary: 'Sauvegarde', detail: res.message});
            this.displayDialog = false;
            this.menu();
          } else {
            alert("Nonnnn");
          }
        });
    }
  }
  actionLink(lien) {
    this.router.navigate(["/"+lien]);
  }
  close() {
    this.displayDialog = false;
    this.Menu = {};
  }
  newDonnee: boolean;
  showDialogToAdd() {
    this.newDonnee = true;
    this.imageUrl = this.myUrl + "/API/api/uploads/default.png";
    this.Menu = {};
    this.displayDialog = true;
  }
  onRowSelect(event) {
    console.log(event);
    this.newDonnee = false;
    var leet = this.myUrl + "/API/api/uploads/" + event.data.images;
    this.imageUrl = leet;
    // alert(leet);
    this.Menu = this.cloneDonnee(event.data);
    this.displayDialog = true;
    console.log(this.Menu);
  }
  cloneDonnee(c: Menu): Menu {
    let Menu = {};
    for (let prop in c) {
      Menu[prop] = c[prop];
    }
    return Menu;
  }
}
