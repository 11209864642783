import { Component, OnInit } from '@angular/core';
import { Menu } from '../model/menu';
import { DataService } from "../data.service";
import { AuthenticationService } from "../authentication.service";
import { environment } from "../../environments/environment";
import { Router } from '@angular/router';
import { Eap } from '../model/eap';

@Component({
  selector: 'app-acceuil',
  templateUrl: './acceuil.component.html',
  styleUrls: ['./acceuil.component.scss']
})
export class AcceuilComponent implements OnInit {
  Menu: Menu = {};
  Menus: Menu[];
  Eap             : Eap = {}; 
  selectedType    : Eap = {};
  TypeD           : Eap[];
  TypeData        : Eap[];
  TypeR           : Eap[];
  TypeRData       : Eap[];
  Eaps            : Eap[];
  rechercheData   : Eap[];
  dataTable       : string;
  typeFile        : any;
  idType          : number;
  checkbox        : boolean = true;
  myUrl : string = `${environment.urlApi}`+ "/API/API/uploads/images/";
  constructor(
    private router:Router,
    private data: DataService,
    public AuthenticationService: AuthenticationService,
    ) { }

  ngOnInit(): void {
    this.typeFile = [
      { label: 'Link', value: 'Link',icon:'' },
      { label: 'Image', value: 'Image',icon:'' },
			{ label: 'Audio', value: 'Audio',icon:''},
			{ label: 'Video', value: 'Video',icon:'' },
		];
    this.Eap.type='Audio';
    this.menu();
    this.type();
    this.regle();
  }
    menu() {
      this.Menu.action = "Select";
      this.data
        .menu(this.Menu)
        .toPromise()
        .then((x: Menu[]) => (this.Menus = x));
    }
    type(){
      this.dataTable = "typepriere";
      this.Eap.action = "Select";
      this.Eap.idUser = this.AuthenticationService.currentUserValue.id;
      this.data
        .donnee(this.dataTable,this.Eap)
        .toPromise()
        .then((x: Eap[]) => (this.TypeD = x,this.typeData()));  
    }
    typeData(){
      this.dataTable = "priere";
      this.Eap.action = "Select";
      this.Eap.idUser = this.AuthenticationService.currentUserValue.id;
      this.data
        .donnee(this.dataTable,this.Eap)
        .toPromise()
        .then((x: Eap[]) => (this.TypeData = x,this.typeData()));  
    }
    regle(){
      this.dataTable = "typeregle";
      this.Eap.action = "Select";
      this.Eap.idUser = this.AuthenticationService.currentUserValue.id;
      this.data
        .donnee(this.dataTable,this.Eap)
        .toPromise()
        .then((x: Eap[]) => (this.TypeR = x,this.regleData()));  
    }
    regleData(){
      this.dataTable = "regle";
      this.Eap.action = "Select";
      this.Eap.idUser = this.AuthenticationService.currentUserValue.id;
      this.data
        .donnee(this.dataTable,this.Eap)
        .toPromise()
        .then((x: Eap[]) => (this.TypeRData = x));  
    }
    selectType(data,table){      
      console.log(this.Eap.type); 
      if (table!='') {
        this.idType=data.id;
      }
      let recherche :any;
      if(table=='priere'){
        recherche = this.TypeData.filter((x) => x.idType == this.idType && x.type ==this.Eap.type);
      }else if(table=='regle'){
        recherche = this.TypeRData.filter((x) => x.idType == this.idType && x.type ==this.Eap.type);
      }else if(table==''){
        if(this.checkbox){
          recherche = this.TypeRData.filter((x) => x.idType == this.idType && x.type ==data);
        }else{
          recherche = this.TypeData.filter((x) => x.idType == this.idType && x.type ==data);
        }        
      }

      
      this.rechercheData=recherche;
    }
    navigateUrl(lien){
      this.router.navigate([lien])
    }
}
