import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { CalendarModule, IslamicService } from '@syncfusion/ej2-angular-calendars';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgxPrintModule} from 'ngx-print';
import { DataTablesModule } from "angular-datatables";
import { InputSwitchModule } from 'primeng/inputswitch';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { AcceuilComponent } from './acceuil/acceuil.component';
import { ParametreUserComponent } from './parametre-user/parametre-user.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';
import {FieldsetModule} from 'primeng/fieldset';
import {CardModule} from 'primeng/card';
import {TableModule} from 'primeng/table';
import {TabViewModule} from 'primeng/tabview';
import {PanelModule} from 'primeng/panel';
import {DividerModule} from 'primeng/divider';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {TooltipModule} from 'primeng/tooltip';
import {OrganizationChartModule} from 'primeng/organizationchart';
import {DialogModule} from 'primeng/dialog';
import {BlockUIModule} from 'primeng/blockui';
import {ToastModule} from 'primeng/toast';
import {TreeTableModule} from 'primeng/treetable';
import {DropdownModule} from 'primeng/dropdown';
import {MultiSelectModule} from 'primeng/multiselect';
import {GalleriaModule} from 'primeng/galleria';
import { ExcelService } from './excel.service';
import { MenuComponent } from './menu/menu.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MenubarModule} from 'primeng/menubar';
import { MenuUserComponent } from './menuUser/menuUser.component';
import { GestionuserComponent } from './gestionuser/gestionuser.component';
import { TreeModule} from 'primeng/tree';
import { ScrollPanelModule} from 'primeng/scrollpanel';
import { FileUploadModule} from 'primeng/fileupload';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { InputNumberModule } from 'primeng/inputnumber';
import { CheckboxModule } from 'primeng/checkbox';
import { SelectButtonModule } from 'primeng/selectbutton';
import { GlobalService } from './global.service';
import { KhatmaComponent } from './khatma/khatma.component';
import { KhatamatComponent } from './khatamat/khatamat.component';
import { PriereComponent } from './priere/priere.component';
import { TypepriereComponent } from './typepriere/typepriere.component';
import { TyperegleComponent } from './typeregle/typeregle.component';
import { GroupeComponent } from './groupe/groupe.component';
import { GestionkhatamatComponent } from './gestionkhatamat/gestionkhatamat.component';
import { KhatmaNewComponent } from './khatmaNew/khatmaNew.component';
import { KhatmaNextComponent } from './khatmaNext/khatmaNext.component';
import { RegleComponent } from './regle/regle.component';

@NgModule({
  declarations: [
    AppComponent,
    AcceuilComponent,
    LoginComponent,
    ParametreUserComponent,
    MenuComponent,
    MenuUserComponent,
    GestionuserComponent,
    KhatmaComponent,
    KhatamatComponent,
    KhatmaNextComponent,
    GroupeComponent,
    PriereComponent,
    TypepriereComponent,
    TyperegleComponent,
    GestionkhatamatComponent,
    KhatmaNewComponent,
    RegleComponent,

  ],
  imports: [ 
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ButtonModule,
    InputTextModule,
    FieldsetModule,
    CardModule,
    TableModule,
    TabViewModule,
    PanelModule,
    DividerModule,
    InputTextareaModule,
    TooltipModule,
    OrganizationChartModule,
    DialogModule,
    BlockUIModule,
    ToastModule,
    TreeTableModule,
    DropdownModule,
    MultiSelectModule,
    GalleriaModule,
    CalendarModule,
    NgxPrintModule,
    DataTablesModule,
    InputSwitchModule,
    PdfViewerModule,
    MenubarModule,
    TreeModule,
    ScrollPanelModule,
    FileUploadModule,
    QRCodeModule,
    NgxQRCodeModule,
    InputNumberModule,
    CheckboxModule,
    SelectButtonModule,

  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy},ExcelService,GlobalService],
  bootstrap: [AppComponent]
})
export class AppModule { }
