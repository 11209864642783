import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../data.service';
import { HttpClient } from '@angular/common/http';
import { MessageService, SortEvent } from 'primeng/api';
import { Eap } from '../model/eap';
import { AuthenticationService } from '../authentication.service';
import { environment } from 'src/environments/environment';
interface UploadEvent {
  originalEvent: Event;
  files: File[];
}
@Component({
  selector: 'app-priere',
  templateUrl: './priere.component.html',
  styleUrls: ['./priere.component.scss'],  
  providers: [MessageService]
})
export class PriereComponent implements OnInit {
  myUrl             : string = `${environment.urlApi}`;
  imageUrl          : string = this.myUrl + "/API/API/uploads/default.png";
  fileToUpload      : File = null;
  uploadedFiles     : any[] = [];

  Eap             : Eap = {}; 
  selectedType    : Eap = {};
  TypeD           : Eap[];
  Eaps            : Eap[];
  khatamat        : Eap[];
  selectedValues  : string[] = [];  
  blockedDocument : boolean;
  displayDialog   : boolean=false;
  dataTable       : string;
  innerWidth      : number;
  innerHeight     : number;
  dateSituation   : string;
  idHizb          : string;  
  typeFile        : any;
  constructor(
    private data: DataService,     
    public AuthenticationService: AuthenticationService,
    private http: HttpClient,
    private messageService: MessageService,
    private router: Router,
    ) { }

  ngOnInit(): void {
    this.dateSituation = new Date().toISOString().split("T")[0];
    this.typeFile = [
      { label: 'Link', value: 'Link',icon:'' },
      { label: 'Image', value: 'Image',icon:'' },
			{ label: 'Audio', value: 'Audio',icon:''},
			{ label: 'Video', value: 'Video',icon:'' },
		];
    this.donnees();
    this.type();
  }
  handleFileInput(file: FileList) {
    this.fileToUpload = file.item(0);
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    };
    reader.readAsDataURL(this.fileToUpload);
  }

  onUpload() {
    // alert(this.Menu.menu);
    this.Eap.chemin = this.myUrl + "/API/API/";
    this.data
      .posteFilePriere(
        this.Eap.id,
        this.Eap.label,
        this.Eap.chemin,
        this.fileToUpload
      )
      .subscribe((data: { message: string; url: string }) => {
        // alert(data);
        // console.log(data);
        alert(data.message);
        this.donnees();
        this.displayDialog = false;
      });
  }
  // onUpload() {
  //   // alert(this.Menu.menu);
  //   this.Eap.chemin = this.myUrl + "/api/api/";
  //   this.data
  //     .posteFileMenu(
  //       this.Eap.id,
  //       this.Eap.label,
  //       this.Eap.chemin,
  //       this.fileToUpload
  //     )
  //     .subscribe((data: { message: string; url: string }) => {
  //       // alert(data);
  //       // console.log(data);
  //       alert(data.message);
  //       this.donnees();
  //       this.displayDialog = false;
  //     });
  // }
  donnees() {
    this.dataTable = "priere";
    this.Eap.action = "Select";
    this.data
      .donnee(this.dataTable,this.Eap)
      .toPromise()
      .then((x: Eap[]) => (this.Eaps = x,console.log(x)));
  }
  type(){
    this.dataTable = "typepriere";
    this.Eap.action = "Select";
    this.Eap.idUser = this.AuthenticationService.currentUserValue.id;
    this.data
      .donnee(this.dataTable,this.Eap)
      .toPromise()
      .then((x: Eap[]) => (this.TypeD = x,console.log(x)));  
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        let value1 = data1[event.field];
        let value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null) result = -1;
        else if (value1 != null && value2 == null) result = 1;
        else if (value1 == null && value2 == null) result = 0;
        else if (typeof value1 === 'string' && typeof value2 === 'string') result = value1.localeCompare(value2);
        else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

        return event.order * result;
    });
}
  save() {
    console.log(this.Eap);    
    if (!this.Eap.designation || !this.Eap.idType || !this.Eap.type ) {
      this.messageService.add({severity:'warn', summary: 'Sauvegarde', detail: "Il manque quelque chose !!!"});
    } else {
      if (this.Eap.id) {
        this.blockedDocument=true;
        this.dataTable = "priere";
        this.Eap.action = "Update";
        this.Eap.idUser = this.AuthenticationService.currentUserValue.id;
        this.data
          .donnee(this.dataTable,this.Eap)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.blockedDocument=false;
              this.displayDialog=false;
              this.donnees();
            } else {
              this.blockedDocument=false;
              this.messageService.add({severity:'warn', summary: 'Attention', detail: res.message});
            }
          });
      } else {
        this.blockedDocument=true;
        this.dataTable  = "priere";
        this.Eap.action = "Insert";
        console.log(this.Eap);        
        this.data
          .donnee(this.dataTable,this.Eap)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.blockedDocument=false;
              this.displayDialog=false;
              this.donnees();
            } else {
              alert(res.message);
            }
          });
      }
    }
  }
  delete(){
    if (confirm("êtes vous sure?")) {
      this.dataTable = "priere";
      this.Eap.action = "Delete";
      this.data
        .donnee(this.dataTable,this.Eap)
        .toPromise()
        .then((res: { message: string }) => {
          if ((res.message = "Opération de supression réuissie")) {
            this.messageService.add({severity:'warn', summary: 'Supression', detail: res.message});
            this.displayDialog = false;
            this.donnees();
          } else {
            alert("Nonnnn");
          }
        });
    } 
  }
  newDonnee: boolean;
  showDialogToAdd() {
    this.newDonnee = true;
    // this.file="";
    // this.imageUrl = this.myUrl + "/API/api/uploads/default.png";
    this.Eap = {};
    this.displayDialog = true;
  }
  onRowSelect(event) {
    console.log(event.data);
    this.newDonnee = false;
    this.Eap = this.cloneDonnee(event.data);
    console.log(this.Eap);
    this.imageUrl=this.myUrl + "/api/api/uploads/images/"+this.Eap.images;
    this.displayDialog = true;
  }
  cloneDonnee(c: Eap): Eap {
    let Type = {};
    for (let prop in c) {
      Type[prop] = c[prop];
    }
    return Type;
  }
}
