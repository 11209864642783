<p-toast [style]="{width: '30%'}"></p-toast>
<p-blockUI [blocked]="blockedDocument">
  <div class="centered">
      <i class="pi pi-spin pi-spinner" style="font-size: 4rem"></i>
     المرجوا الانتظار ...
  </div>
</p-blockUI>
<br />

<p-card [style]="{ 'margin-left': '30px' }">
  <h1 class="ribbon">
    Gestion Khatamats
    <!-- <button
      style="float: right; margin-top: 10px; margin-right: 10px"
      modePaiement="button"
      pButton
      icon="pi pi-plus"
      iconPos="right"
      (click)="showDialogToAdd()"
      label="Ajouter une nouvelle {{dataTable}} "
    ></button> -->
  </h1>
  <br />
  <p-table
      #dt1
      [value]="Donnees"
      selectionMode="single"
      [(selection)]="selectedDonnee"
      (onRowSelect)="onRowSelect($event)"
      [paginator]="true"
      [rows]="10"
    >
    <ng-template pTemplate="header">
      <tr>
        <!-- <th [pSortableColumn]="'id'"><p-sortIcon [field]="'id'"></p-sortIcon>ID</th> -->
        <th [pSortableColumn]="'designation'"><p-sortIcon [field]="'designation'"></p-sortIcon>Groupe</th>
        <th [pSortableColumn]="'rythme'"><p-sortIcon [field]="'rythme'"></p-sortIcon>Rythme</th>
        <th [pSortableColumn]="'date'"><p-sortIcon [field]="'date'"></p-sortIcon>Date</th>
        <th [pSortableColumn]="'hizb'"><p-sortIcon [field]="'Hizb'"></p-sortIcon>Hizb</th>
        <th [pSortableColumn]="'nom'"><p-sortIcon [field]="'nom'"></p-sortIcon>Nom</th>
        <th [pSortableColumn]="'prenom'"><p-sortIcon [field]="'prenom'"></p-sortIcon>Prénom</th>
        <th [pSortableColumn]="'username'"><p-sortIcon [field]="'username'"></p-sortIcon>User Name</th>
        <th [pSortableColumn]="'groupe'"><p-sortIcon [field]="'groupe'"></p-sortIcon>Profille</th>
        <!-- <th [pSortableColumn]="'visible'"><p-sortIcon [field]="'visible'"></p-sortIcon>Actif</th> -->
        <!-- <th>Visible</th> -->
      </tr>
      <tr>
        <th>
          <input style="width: 100%;" pInputText type="text" (input)="dt1.filter($event.target.value, 'designation', 'contains')" [value]="dt1.filters['designation']?.value" placeholder="designation" class="p-column-filter">
        </th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-User>
      <tr [pSelectableRow]="User">
        <td>{{ User.designation }}</td>
        <td>{{ User.rythme }}</td>
        <td>{{ User.date | date :'dd/MM/yyyy'}}</td>
        <td>{{ User.hizb }}</td>
        <td>{{ User.nom }}</td>
        <td>{{ User.prenom }}</td>
        <td>{{ User.username }}</td>
        <td>{{ User.groupe }}</td>
      </tr>
    </ng-template>
  </p-table>
</p-card>
<p-dialog
  header="{{Donnee.prenom + ' ' + Donnee.nom}}"
  [(visible)]="displayDialog"
  [focusOnShow]="false"
  [responsive]="true"
  showEffect="fade"
  [modal]="true"
  [style]="{ 'width': '450px' }"
>
  <div class="container">
    <div class="child">
      <input
        style="text-align: center;width: 100%;"
        type="date"
        pInputText
        [(ngModel)]="dateSituation"/>
    </div>
  </div>
  <div class="container">
    <div class="child">
      <span class="p-float-label">
        <input
          id="nom"
          type="number"
          size="30"
          pInputText
          [(ngModel)]="Donnee.hizb"
          style="text-align: center;width: 100%;" required
        />
        <label htmlFor="nom">الحزب</label>
      </span>
    </div>      
  </div>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-helper-clearfix">
      <button
        type="button"
        pButton
        *ngIf="ablee"
        class="ui-button-danger"
        icon="pi pi-times"
        (click)="delete()"
        label="Supprimer"
      ></button>
      <button
        style="float: right; margin-top: 10px; margin-right: 10px"
        modePaiement="button"
        pButton
        icon="pi pi-sign-out"
        (click)="displayDialog=false"
        label="Fermer"
      ></button>
      <button
        *ngIf="Donnee.hizb && dateSituation"
        style="float: right; margin-top: 10px; margin-right: 10px"
        type="button"
        pButton
        icon="pi pi-check"
        (click)="save()"
        label="Sauvegarder"
      ></button>
    </div>
  </p-footer>
</p-dialog>
