
<div style="display: flex;flex-direction:column;justify-content: center;margin:auto; ">
    <div class="custom-checkbox">
        <input id="status" 
                [(ngModel)]="checkbox"
                type="checkbox" 
                name="status">
        <label for="status">
            <div class="status-switch"
                data-unchecked="الأدعية"
                data-checked="القواعد">
            </div>
        </label>
    </div>
    <div class="radio-toolbar" style="display: flex;margin: auto;">
        <div *ngFor="let type of typeFile">
            <input
            type="radio"
            id="radio{{type.label}}"
            name="radiosF"
            [value]="type.label"
            [(ngModel)]="Eap.type"
            (click)="selectType(type.label,'')"
            />
            <label for="radio{{type.label}}">{{type.label}}
                <div *ngIf="type.label=='Link'">
                    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"/></svg>
                </div>
                <div *ngIf="type.label=='Audio'">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 122.88 110.35" style="enable-background:new 0 0 122.88 110.35" xml:space="preserve"><g><path d="M6.32,0v6.22h12.22V0h6.32v14.88h73.15V0h6.32v6.22h12.22V0h6.32v110.35h-6.32v-7.19h-12.22v7.19h-6.32V94.79H24.87v15.56 h-6.32v-7.19H6.32v7.19H0V0H6.32L6.32,0z M73.58,60.81c0.93-0.16,1.81-0.13,2.58,0.05v-18.1l-19.16,5.5v21.02 c0.01,0.11,0.02,0.23,0.02,0.35c0,0,0,0,0,0c0,2.83-2.97,5.64-6.63,6.27c-3.66,0.63-6.63-1.15-6.63-3.98 c0-2.83,2.97-5.64,6.63-6.27c1.38-0.24,2.66-0.13,3.72,0.24l0-25.88h0.16l24.79-5.68v29.15c0.04,0.21,0.07,0.43,0.07,0.65 c0,0,0,0,0,0c0,2.36-2.48,4.71-5.54,5.24c-3.06,0.53-5.54-0.96-5.54-3.33C68.04,63.68,70.52,61.33,73.58,60.81L73.58,60.81 L73.58,60.81z M98.01,21.2H24.87v67.27h73.15V21.2L98.01,21.2z M116.56,96.84v-11.8h-12.22v11.8H116.56L116.56,96.84z M116.56,78.72v-11.8h-12.22v11.8H116.56L116.56,78.72z M116.56,60.59v-11.8h-12.22v11.8H116.56L116.56,60.59z M116.56,42.47v-11.8 h-12.22v11.8H116.56L116.56,42.47z M116.56,24.35v-11.8h-12.22v11.8H116.56L116.56,24.35z M18.54,96.84v-11.8H6.32v11.8H18.54 L18.54,96.84z M18.54,78.72v-11.8H6.32v11.8H18.54L18.54,78.72z M18.54,60.59v-11.8H6.32v11.8H18.54L18.54,60.59z M18.54,42.47 v-11.8H6.32v11.8H18.54L18.54,42.47z M18.54,24.35v-11.8H6.32v11.8H18.54L18.54,24.35z"/></g></svg>
                </div>
                <div *ngIf="type.label=='Image'">              
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 122.88 110.35" style="enable-background:new 0 0 122.88 110.35" xml:space="preserve"><g><path d="M6.32,0v6.22h12.22V0h6.32v14.88h73.15V0h6.32v6.22h12.22V0h6.32v110.35h-6.32v-7.19h-12.22v7.19h-6.32V94.79H24.87v15.56 h-6.32v-7.19H6.32v7.19H0V0H6.32L6.32,0z M44.67,35.77h33.53c0.76,0,1.45,0.31,1.95,0.81s0.81,1.19,0.81,1.95v33.3 c0,0.76-0.31,1.45-0.81,1.95c-0.5,0.5-1.19,0.81-1.95,0.81H44.67c-0.76,0-1.45-0.31-1.95-0.81c-0.5-0.5-0.81-1.19-0.81-1.95v-33.3 c0-0.76,0.31-1.45,0.81-1.95C43.23,36.08,43.92,35.77,44.67,35.77L44.67,35.77L44.67,35.77z M44.81,63.83l11.25-10.77 c0.45-0.42,1.16-0.37,1.56,0.08c0.01,0.01,0.01,0.02,0.02,0.02l10,11.83l1.53-9.41c0.1-0.6,0.67-1.02,1.27-0.92 c0.23,0.03,0.44,0.15,0.6,0.3l7.01,7.37V39.19c0-0.15-0.06-0.28-0.16-0.37c-0.09-0.09-0.23-0.16-0.37-0.16h-32.2 c-0.15,0-0.28,0.06-0.37,0.16c-0.09,0.09-0.16,0.23-0.16,0.37v24.64H44.81L44.81,63.83L44.81,63.83z M70.68,42.08 c1.1,0,2.11,0.45,2.83,1.17c0.73,0.73,1.17,1.73,1.17,2.83c0,1.1-0.45,2.11-1.17,2.83c-0.73,0.73-1.73,1.17-2.83,1.17 c-1.1,0-2.11-0.45-2.83-1.17c-0.73-0.73-1.17-1.73-1.17-2.83c0-1.1,0.45-2.11,1.17-2.83C68.59,42.52,69.58,42.08,70.68,42.08 L70.68,42.08z M98.01,21.2H24.87v67.27h73.15V21.2L98.01,21.2z M116.56,96.84v-11.8h-12.22v11.8H116.56L116.56,96.84z M116.56,78.72v-11.8h-12.22v11.8H116.56L116.56,78.72z M116.56,60.59v-11.8h-12.22v11.8H116.56L116.56,60.59z M116.56,42.47v-11.8 h-12.22v11.8H116.56L116.56,42.47z M116.56,24.35v-11.8h-12.22v11.8H116.56L116.56,24.35z M18.54,96.84v-11.8H6.32v11.8H18.54 L18.54,96.84z M18.54,78.72v-11.8H6.32v11.8H18.54L18.54,78.72z M18.54,60.59v-11.8H6.32v11.8H18.54L18.54,60.59z M18.54,42.47 v-11.8H6.32v11.8H18.54L18.54,42.47z M18.54,24.35v-11.8H6.32v11.8H18.54L18.54,24.35z"/></g></svg>
                </div>
                <div *ngIf="type.label=='Video'">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 122.88 110.35" style="enable-background:new 0 0 122.88 110.35" xml:space="preserve"><style type="text/css">.st0{fill-rule:evenodd;clip-rule:evenodd;}</style><g><path class="st0" d="M61.44,37.35c9.84,0,17.82,7.98,17.82,17.82c0,9.84-7.98,17.82-17.82,17.82c-9.84,0-17.82-7.98-17.82-17.82 C43.62,45.33,51.6,37.35,61.44,37.35L61.44,37.35z M6.32,0v6.22h12.22V0h6.32v14.88h73.15V0h6.32v6.22h12.22V0h6.32v110.35h-6.32 v-7.19h-12.22v7.19h-6.32V94.79H24.87v15.56h-6.32v-7.19H6.32v7.19H0V0H6.32L6.32,0z M98.01,21.2H24.87v67.27h73.15V21.2 L98.01,21.2z M116.56,96.84v-11.8h-12.22v11.8H116.56L116.56,96.84z M116.56,78.72v-11.8h-12.22v11.8H116.56L116.56,78.72z M116.56,60.59v-11.8h-12.22v11.8H116.56L116.56,60.59z M116.56,42.47v-11.8h-12.22v11.8H116.56L116.56,42.47z M116.56,24.35v-11.8 h-12.22v11.8H116.56L116.56,24.35z M18.54,96.84v-11.8H6.32v11.8H18.54L18.54,96.84z M18.54,78.72v-11.8H6.32v11.8H18.54 L18.54,78.72z M18.54,60.59v-11.8H6.32v11.8H18.54L18.54,60.59z M18.54,42.47v-11.8H6.32v11.8H18.54L18.54,42.47z M18.54,24.35 v-11.8H6.32v11.8H18.54L18.54,24.35z M70.35,55.16l-14.03,9.42v-18.7L70.35,55.16L70.35,55.16z"/></g></svg>
                </div>
            </label>
        </div>
    </div>
    <div class="flex-container" *ngIf="!checkbox" dir="rtl">
        <div [ngClass]="{'itemsType': item.id!=idType, 'itemsTypeSet' : item.id==idType }" *ngFor="let item of TypeD" (click)="selectType(item,'priere')" >
            {{item.label}}
        </div>
    </div>
    <div class="flex-container" *ngIf="checkbox" dir="rtl">
        <div [ngClass]="{'itemsType': item.id!=idType, 'itemsTypeSet' : item.id==idType }" *ngFor="let item of TypeR" (click)="selectType(item,'regle')" >
            {{item.label}}
        </div>
    </div>
    <div class="flex-container" *ngIf="rechercheData" dir="rtl" style="padding-top: 3px;">
        <div *ngFor="let item of rechercheData" style="width:100%;height:100%;padding-top: 10px;" >
            <img  *ngIf="item.type=='Image'" [src]="myUrl+item.images" style="width: 150px" />
            <!-- {{imageUrl.name.split('.').pop()}} -->
            <div *ngIf="item.type=='Audio'" style="display: flex;justify-content:space-between">
                <div>{{item.titre}}</div>
                <div>
                    <audio controls style="height: 40px; ">
                        <source [src]="myUrl+item.images" type="audio/ogg">
                        <source [src]="myUrl+item.images" type="audio/mpeg">
                    </audio>
                </div>
            </div>
            <video width="320" height="240" controls *ngIf="item.type=='Video'">
              <source [src]="myUrl+item.images" type="video/mp4">
              <source [src]="myUrl+item.images" type="video/ogg">
            </video>
            <a href="{{item.link}}" *ngIf="item.type=='Link'" target="_blank">{{item.titre}}</a>
        </div>
    </div>
</div>