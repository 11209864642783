<div class="area" >
    <div *ngIf="AuthenticationService.currentUserValue" class="noPrint">                    
        <p-menubar [model]="items" *ngIf="AuthenticationService.currentUserValue.groupe=='Administrateurs' || AuthenticationService.currentUserValue.groupe=='Administrateur'">
            <ng-template pTemplate="start" >
                <img src="assets/logo.png" height="40" class="p-mr-2">
            </ng-template>
            <ng-template pTemplate="end">
                {{AuthenticationService.currentUserValue.nom+' '+AuthenticationService.currentUserValue.prenom}} مرحبا بك 
            </ng-template>
        </p-menubar> 
        <div *ngIf="AuthenticationService.currentUserValue.groupe!='Administrateurs' && AuthenticationService.currentUserValue.groupe!='Administrateur'"class="noPrint">
            <p-menubar [model]="itemsUser" class="noPrint" >
                <ng-template pTemplate="start">
                    <img src="assets/logo.png" height="40" class="p-mr-2">
                </ng-template>
                <ng-template pTemplate="end">
                    <div dir="rtl">
                        مرحبا بك 
                        <b>
                            {{AuthenticationService.currentUserValue.nom+' '+AuthenticationService.currentUserValue.prenom}} 
                        </b>
                    </div>                    
                </ng-template>
            </p-menubar>
        </div>   
</div>
<router-outlet></router-outlet>
