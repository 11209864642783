import { Component, OnInit } from "@angular/core";
import { DataService } from "../data.service";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import {Message,MessageService} from 'primeng/api';
import { Router } from '@angular/router';
import { AuthenticationService } from "src/app/authentication.service";
import { User } from "../model/user";
import { Eap } from "../model/eap";

@Component({
  selector: 'app-gestionuser',
  templateUrl: './gestionuser.component.html',
  styleUrls: ['./gestionuser.component.scss'],
  providers: [MessageService],
})
export class GestionuserComponent implements OnInit {
  myUrl             : string = `${environment.urlApi}`;
  imageUrl          : string = this.myUrl + "/api/api/uploads/default.png";
  fileToUpload      : File = null;
  Donnee            : User = {};
  Donnees           : User[];
  Groupe            : Eap = {};
  Groupes           : Eap[];
  selectedDonnee    : User;
  displayDialog     : boolean;
  blockedDocument   : boolean;
  ablee             : boolean;
  selectedOption    : string;
  dataTable         : string;
  groupes           : any;
  selectedFile = null;
  stateOptions: any[] = [{label: 'Off', value: 0}, {label: 'On', value: 1}];
  constructor(
    private data: DataService, private http: HttpClient,
    private messageService: MessageService,
    public AuthenticationService: AuthenticationService,
    private router: Router) {}
  ngOnInit() {
    this.dataTable = "user";
    this.donnee();
    this.groupes = [
			// { label: 'Administrateurs', value: 'Administrateurs' },
			{ label: 'Administrateur', value: 'Administrateur' },
			{ label: 'Utilisateur', value: 'Utilisateur' },
		];
    this.blockedDocument=false;
    // this.Donnee.visible=1;
  }

  handleFileInput(file: FileList) {
    this.fileToUpload = file.item(0);
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    };
    reader.readAsDataURL(this.fileToUpload);
  }

  // onUpload() {
  //   // alert(this.ModePaiement.modePaiement);
  //   this.Donnee.LIBELLEARABE = this.myUrl + "/api/api/";
  //   this.data
  //     .posteFile(
  //       this.Donnee.id,
  //       this.Donnee.LIBELLE,
  //       this.Donnee.LIBELLEARABE,
  //       this.fileToUpload
  //     )
  //     .subscribe((data: { message: string; url: string }) => {
  //       // alert(data);
  //       // console.log(data);
  //       this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: data.message});
  //       this.donnee();
  //       this.displayDialog = false;
  //     });
  // }
  donnee() {
    this.Donnee.action = "Select";
    this.dataTable="user";
    this.data
      .donnee(this.dataTable,this.Donnee)
      .toPromise()
      .then((x: User[]) => (this.Donnees = x,this.groupe()));
  }
  groupe(){
    this.Groupe.action = "Select";
    this.dataTable="groupe";
    this.data
      .donnee(this.dataTable,this.Groupe)
      .toPromise()
      .then((x: Eap[]) => (this.Groupes = x));   
  }
  save() {
    if (this.Donnee.nom == null || this.Donnee.nom =="") {
      this.messageService.add({severity:'warn', summary: 'Sauvegarde', detail: 'Le nom est vide -  النسب فارغ'});              
    } else if (this.Donnee.prenom == null) {
      this.messageService.add({severity:'warn', summary: 'Sauvegarde', detail: 'prenom est vide - الاسم فارغ'});              
    } else if (this.Donnee.username == null) {
      this.messageService.add({severity:'warn', summary: 'Sauvegarde', detail: 'username est vide - الاسم فارغ'});              
    } else if (this.Donnee.password == null) {
      this.messageService.add({severity:'warn', summary: 'Sauvegarde', detail: 'password est vide - الرقم السري فارغ'});              
    } else {
      if (this.Donnee.id) {
        this.Donnee.action = "Update";
        this.dataTable="user";
        this.data
          .donnee(this.dataTable,this.Donnee)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.displayDialog = false;
              this.donnee();
            } else {
              console.log(res);
              alert(res.message);
              alert("Nonnnn");
            }
          });
      } else {
        this.Donnee.action = "Insert";
        this.dataTable="user"; 
        this.data
          .donnee(this.dataTable,this.Donnee)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.displayDialog = false;
              this.donnee();
            } else {
              alert(res.message);
              this.displayDialog = false;
            }
          });
      }
    }
  }
  delete() {
    if (confirm("êtes vous sure?")) {
      this.Donnee.dateNow = new Date().toISOString().split("T")[0]; 
      this.Donnee.action = "Delete";
      this.dataTable="user";
      this.data
        .donnee(this.dataTable,this.Donnee)
        .toPromise()
        .then((res: { message: string }) => {
          if ((res.message = "Opération de supression réuissie")) {
            this.messageService.add({severity:'warn', summary: 'Supression', detail: res.message});
            this.displayDialog = false;
            this.donnee();
          } else {
            alert("Nonnnn");
          }
        });
    }
  }
  actionLink(lien) {
    this.router.navigate(["/"+lien]);
  }
  close() {
    this.displayDialog = false;
    this.Donnee = {};
  }
  newDonnee: boolean;
  showDialogToAdd() {
    this.newDonnee = true;
    this.imageUrl = this.myUrl + "/API/api/uploads/default.png";
    this.Donnee = {};
    // this.Donnee.visible=1 ;
    this.displayDialog = true;
  }
  onRowSelect(event) {
    // console.log(event);
    this.newDonnee = false;
    var leet = this.myUrl + "/API/api/uploads/images/" + event.data.images;
    this.imageUrl = leet;
    // alert(leet);
    this.Donnee = this.cloneDonnee(event.data);
    this.displayDialog = true;
  }
  cloneDonnee(c: User): User {
    let Donnee = {};
    for (let prop in c) {
      Donnee[prop] = c[prop];
    }
    return Donnee;
  }
}