import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../data.service';
import { HttpClient } from '@angular/common/http';
import { MessageService, SortEvent } from 'primeng/api';
import { Eap } from '../model/eap';
import { AuthenticationService } from '../authentication.service';

@Component({
  selector: 'app-khatma',
  templateUrl: './khatma.component.html',
  styleUrls: ['./khatma.component.scss'],  
  providers: [MessageService]
})
export class KhatmaComponent implements OnInit {

  Eap             : Eap = {};
  Eaps            : Eap[];
  khatamat        : Eap[];
  selectedValues  : string[] = [];  
  blockedDocument : boolean;
  dataTable       : string;
  innerWidth      : number;
  innerHeight     : number;
  dateSituation   : string;
  idHizb          : string;
  constructor(
    private data: DataService,     
    public AuthenticationService: AuthenticationService,
    private http: HttpClient,
    private messageService: MessageService,
    private router: Router,
    ) { }

  ngOnInit(): void {
    this.dateSituation = new Date().toISOString().split("T")[0];
    this.donnees();
    this.rechercher();
    this.innerWidth = window.innerWidth;
    this.innerHeight = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight)-120;
  }
  @HostListener('window:resize')
  onResize() {this.updatePageDimensions();}
  updatePageDimensions() {
    this.innerHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    this.innerHeight =this.innerHeight-120;
    this.innerWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    this.innerWidth =this.innerWidth-10;
  }
  generateidHizb(selectedValues){
    console.log(selectedValues);
    this.idHizb="";
    if (selectedValues.length>0) {
      for (let data of this.selectedValues) {
        this.idHizb += "("+data+",'"+ this.dateSituation+"',"+this.AuthenticationService.currentUserValue.id+"),";
      }
      this.idHizb += ',';
      this.idHizb=this.idHizb.replace(",,", "");
      console.log(this.idHizb);      
    }    
  }
  updateAhzab(){
    // console.log(this.Eaps);
    // console.log(this.khatamat);
    // console.log(this.dateSituation);
    this.selectedValues=[];
    this.Eaps.forEach((row,index)=>{
      this.khatamat
      let updateItem = this.khatamat.find(item=>item.hizb==row.id && item.dateSituation==this.dateSituation );
      
      if (updateItem===undefined){
        // this.tableVentess[index].avance = 0;
        // this.tableVentess[index].reste = this.tableVentess[index].total;  
        this.Eaps[index].cheked=false;
        
      }else{
        // console.log(updateItem);
        this.selectedValues.push( updateItem.hizb.toString())
        this.Eaps[index].cheked=true;
          // this.tableVentess[index].avance = updateItem.montant;
          // this.tableVentess[index].reste = this.tableVentess[index].total-updateItem.montant;
      }
    });
    // console.log(this.selectedValues);
    
  }
  donnees() {
    this.dataTable = "ahzab";
    this.Eap.action = "Select";
    this.data
      .donnee(this.dataTable,this.Eap)
      .toPromise()
      .then((x: Eap[]) => (this.Eaps = x));
  }
  rechercher(){
    this.dataTable = "khatamat";
    this.Eap.action = "SelectUser";
    this.Eap.idUser = this.AuthenticationService.currentUserValue.id;
    this.data
      .donnee(this.dataTable,this.Eap)
      .toPromise()
      .then((x: Eap[]) => (this.khatamat = x,this.updateAhzab()));  
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        let value1 = data1[event.field];
        let value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null) result = -1;
        else if (value1 != null && value2 == null) result = 1;
        else if (value1 == null && value2 == null) result = 0;
        else if (typeof value1 === 'string' && typeof value2 === 'string') result = value1.localeCompare(value2);
        else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

        return event.order * result;
    });
}
  save() {
    if (this.dateSituation == null ) {
      this.messageService.add({severity:'warn', summary: 'Sauvegarde', detail: "Il manque quelque chose !!!"});
    } else {
      if (this.Eap.id) {
        this.blockedDocument=true;
        this.dataTable = "ahzab";
        this.Eap.action = "Update";
        this.Eap.idUser = this.AuthenticationService.currentUserValue.id;
        this.data
          .donnee(this.dataTable,this.Eap)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.blockedDocument=false;
              this.rechercher();
            } else {
              this.blockedDocument=false;
              this.messageService.add({severity:'warn', summary: 'Attention', detail: res.message});
            }
          });
      } else {
        this.blockedDocument=true;
        this.dataTable  = "khatamat";
        this.Eap.action = "Insert";
        this.Eap.idHizb = this.idHizb;
        this.Eap.idUser = this.AuthenticationService.currentUserValue.id;
        this.Eap.dateSituation = this.dateSituation;
        console.log(this.Eap);        
        this.data
          .donnee(this.dataTable,this.Eap)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.blockedDocument=false;
              this.rechercher();
            } else {
              alert(res.message);
            }
          });
      }
    }
  }
}
