<div class="area" >
    <div class="container">
        <div class="child">
            <p-dropdown
                *ngIf="able"
                [options]="Groupes"
                [(ngModel)]="groupeTeam"
                placeholder="Groupe"
                id="Groupe"
                [showClear]="true"
                (onChange)="rechercher()"
                [style]="{ width: '100%' }">
                {{ groupe }}</p-dropdown>   
        </div>
        <div class="child">
            <input
                type="date"
                pInputText
                [(ngModel)]="dateSituation"
                (change)="updateAhzab()"
                style="height: 32px; float: right; margin-top: 10px; margin-right: 10px"
                />            
        </div>
    </div>
    <div style="display: flex; flex-direction: column ;padding: 20px;">
        <div class="fontArab" dir="rtl" style="display: flex;flex-wrap: wrap; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-weight: bold;">
            <div [disabled]="true" id="ck-button" *ngFor="let item of Eaps" dir="rtl" [ngStyle]="item.nom ? {'background-color':  '#ffb732','color':'#fff','font-size': 'smaller'} : {'background-color':  '','color':'black'}">
                <label>
                    <input 
                    type="checkbox" 
                    value="{{item.id}}">
                    <span>الحـــزب {{item.id}}</span>
                    <span style="font-size: x-small;">{{item.nom ? item.nom : '' }}</span>
                </label>
            </div>
            <!-- <div *ngFor="let item of Eaps" style="padding: 5px;">
                <p-checkbox 
                    name="groupname" 
                    value="{{item.id}}" 
                    pTooltip="{{item.nom}}"
                    [disabled]="true"  
                    label="الحـــزب  {{item.id}} {{item.nom ? item.nom : '' }}" 
                    [(ngModel)]="selectedValues" 
                    (onChange)="generateidHizb(selectedValues)" 
                    [style]="{'padding ': '25px'}"></p-checkbox>
            </div> -->
        </div>
        <!-- <p-chart type="pie" [data]="data" [options]="options"></p-chart> -->
    </div>    
</div>

