<p-toast [style]="{width: '30%'}"></p-toast>
<p-blockUI [blocked]="blockedDocument">
  <div class="centered">
      <i class="pi pi-spin pi-spinner" style="font-size: 4rem"></i>
     المرجوا الانتظار ...
  </div>
</p-blockUI>
<br />

<p-card [style]="{ 'margin-left': '30px' }">
  <h1 class="ribbon">
    Gestion des {{dataTable}}s
    <button
      style="float: right; margin-top: 10px; margin-right: 10px"
      modePaiement="button"
      pButton
      icon="pi pi-plus"
      iconPos="right"
      (click)="showDialogToAdd()"
      label="Ajouter une nouvelle {{dataTable}} "
    ></button>
  </h1>
  <br />
  <p-table
    #dt1
    [value]="Donnees"
    selectionMode="single"
    [(selection)]="selectedDonnee"
    (onRowSelect)="onRowSelect($event)"
    [paginator]="true"
    [rows]="10"
  >
  <ng-template pTemplate="header">
    <tr>
      <!-- <th [pSortableColumn]="'id'"><p-sortIcon [field]="'id'"></p-sortIcon>ID</th> -->
      <th [pSortableColumn]="'designation'"><p-sortIcon [field]="'designation'"></p-sortIcon>المجموعة</th>
      <th [pSortableColumn]="'rythme'"><p-sortIcon [field]="'rythme'"></p-sortIcon>عدد الأحزاب</th>
      <th [pSortableColumn]="'nom'"><p-sortIcon [field]="'nom'"></p-sortIcon>النسب</th>
      <th [pSortableColumn]="'prenom'"><p-sortIcon [field]="'prenom'"></p-sortIcon>الإسم</th>
      <th [pSortableColumn]="'username'"><p-sortIcon [field]="'username'"></p-sortIcon>الرقم</th>
      <th [pSortableColumn]="'groupe'"><p-sortIcon [field]="'groupe'"></p-sortIcon>Profille</th>
      <!-- <th [pSortableColumn]="'visible'"><p-sortIcon [field]="'visible'"></p-sortIcon>Actif</th> -->
      <!-- <th>Visible</th> -->
    </tr>
    <tr>
      <th>
        <input style="width: 100%;" pInputText type="text" (input)="dt1.filter($event.target.value, 'designation', 'contains')" [value]="dt1.filters['designation']?.value" placeholder="designation" class="p-column-filter">
      </th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-User>
    <tr [pSelectableRow]="User">
      <!-- <td>{{ User.id }}</td> -->
      <td>{{ User.designation }}</td>
      <td>{{ User.rythme }}</td>
      <td>{{ User.nom }}</td>
      <td>{{ User.prenom }}</td>
      <td>{{ User.username }}</td>
      <td>{{ User.groupe }}</td>
      <!-- <td align="center">
        <div *ngIf="User.visible == 1">
          <img
            src="./assets/images/actif.png"
            height="40px"
            width="40px"
          />
        </div>
        <div *ngIf="User.visible != 1">
          <img
            src="./assets/images/inactif.png"
            height="40px"
            width="40px"
          />
        </div>
      </td> -->
      <!-- <td>{{ User.visible }}</td> -->
    </tr>
  </ng-template>
  </p-table>
</p-card>
<p-dialog
  header="Gestion des utilisateur"
  [(visible)]="displayDialog"
  [focusOnShow]="false"
  [responsive]="true"
  showEffect="fade"
  [modal]="true"
  [style]="{ 'width': '450px' }"
>
<div class="container">
  <div class="child">
  <span class="p-float-label">
    <p-dropdown
      [disabled]="Donnee.visible==0"
      [options]="Groupes"
      [(ngModel)]="Donnee.groupeTeam"
      placeholder="Groupe"
      id="Groupe"
      [showClear]="true"
      [style]="{ width: '100%' }">
      {{ Donnee.groupe }}</p-dropdown>
    <label htmlFor="Groupe">Groupe</label>
  </span>    
  </div>
</div>
<div class="container">
  <div class="child">
  <span class="p-float-label">
    <p-dropdown
      [disabled]="Donnee.visible==0 "
      [options]="groupes"
      [(ngModel)]="Donnee.groupe"
      placeholder="Groupe"
      id="Groupe"
      [showClear]="true"
      [style]="{ width: '100%' }">
      {{ Donnee.groupe }}</p-dropdown>
    <label htmlFor="Groupe">Groupe</label>
  </span>    
  </div>
</div>
<div class="container">
  <div class="child">
    <span class="p-float-label">
      <input
        [disabled]="Donnee.visible==0"
        id="nom"
        type="text"
        size="30"
        pInputText
        [(ngModel)]="Donnee.nom"
        (click)="$event.target.select()"
        style="text-align: center;width: 100%;" required
      />
      <label htmlFor="nom">Nom</label>
    </span>
  </div>
  <div class="child">
    <span class="p-float-label">
      <input
        [disabled]="Donnee.visible==0"
        id="prenom"
        type="text"
        size="30"
        pInputText
        [(ngModel)]="Donnee.prenom"
        (click)="$event.target.select()"
        style="text-align: center;width: 100%;" required
      />
      <label htmlFor="prenom">Prénom</label>
    </span>
  </div>
</div>
<div class="container">
  <div class="child">
    <span class="p-float-label">
      <input
        [disabled]="Donnee.visible==0"
        id="username"
        type="text"
        size="30"
        pInputText
        [(ngModel)]="Donnee.username"
        (click)="$event.target.select()" required
        style="text-align: center;width: 100%;"
      />
      <label htmlFor="username">user name</label>
    </span>
  </div>
  <div class="child">
    <span class="p-float-label">
      <input
        [disabled]="Donnee.visible==0"
        id="password"
        type="text"
        size="30"
        pInputText
        [(ngModel)]="Donnee.password"
        (click)="$event.target.select()"
        style="text-align: center;width: 100%;" required
      />
      <label htmlFor="password">Mot de passe</label>
    </span>
  </div>
</div>

  <p-footer>
    <div class="ui-dialog-buttonpane ui-helper-clearfix">
      <button
        type="button"
        pButton
        *ngIf="ablee"
        class="ui-button-danger"
        icon="pi pi-times"
        (click)="delete()"
        label="Supprimer"
      ></button>
      <button
        style="float: right; margin-top: 10px; margin-right: 10px"
        modePaiement="button"
        pButton
        icon="pi pi-sign-out"
        (click)="displayDialog=false"
        label="Fermer"
      ></button>
      <button
        *ngIf="Donnee.nom && Donnee.prenom && Donnee.username && Donnee.groupeTeam && Donnee.groupe && Donnee.password"
        style="float: right; margin-top: 10px; margin-right: 10px"
        type="button"
        pButton
        icon="pi pi-check"
        (click)="save()"
        label="Sauvegarder"
      ></button>
    </div>
  </p-footer>
</p-dialog>
