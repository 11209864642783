<div class="area" >
    <p-toast [style]="{width: '350px'}"></p-toast>
    <input
        type="date"
        pInputText
        [(ngModel)]="dateSit"
        (change)="donnees()"
        style="height: 32px; float: right; margin-top: 10px; margin-right: 10px"
        /> <p-button (click)="copyInputMessage(userinput)" label="نســــخ" icon="pi pi-copy" styleClass="p-button-raised p-button-secondary"></p-button>
    <textarea id="w3review" [(ngModel)]="elementToClipBoard" name="w3review" rows="40" cols="50" style="width: 100%;font-size: larger;" #userinput dir="rtl"></textarea><br>
   
    <!-- <p-checkbox [(ngModel)]="checked" (click)='searchNextHizb(checked)' [binary]="true" inputId="binary"></p-checkbox> -->
    <!-- <div class="card">
        <p-table [value]="tablePlaning" dir="rtl">
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="ancienCode" style="width: 50px">الرقم<p-sortIcon field="ancienCode"></p-sortIcon></th>
                    <th pSortableColumn="nomPrenom">الاسم<p-sortIcon field="nomPrenom"></p-sortIcon></th>
                    <th pSortableColumn="hizbNextt">الأحزاب <p-sortIcon field="hizbNextt"></p-sortIcon></th>
                    <th pSortableColumn="hizbNext">الأحزاب القادمة <p-sortIcon field="hizbNext"></p-sortIcon></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-product>
                <tr>
                    <td>{{ product.ancienCode }}</td>
                    <td>{{ product.nomPrenom}}</td>
                    <td>{{ product.hizbNextt}}</td>
                    <td>{{ product.hizbNext }}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <div class="card">
        <p-table [value]="Eaps" [paginator]="true" [rows]="5" dir="rtl">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 120px">التاريخ</th>
                    <th style="width: 50px">الرقم</th>
                    <th>الاسم</th>
                    <th>الحـــزب</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-product>
                <tr>
                    <td>{{ product.dateSituation }}</td>
                    <td>{{ product.code }}</td>
                    <td>{{ product.nom+ ' ' +product.prenom}}</td>
                    <td>{{ product.hizb }}</td>
                </tr>
            </ng-template>
        </p-table>
    </div> -->
    <!-- {{Eaps|json}}   -->
</div>

