<div class="area" >
    <p-toast [style]="{width: '350px'}"></p-toast>
    <div style="display: flex; flex-direction: column ;padding: 20px;">
        <div class="fontArab" dir="rtl" style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-weight: bold;">
            <input
                type="date"
                pInputText
                [(ngModel)]="dateSituation"
                (change)="updateAhzab()"
                style="height: 32px; float: right; margin-top: 10px; margin-right: 10px"
                />
                <!-- <span style="margin-left: 5px"></span>
            <p-button icon="pi pi-search" styleClass="p-button-raised p-button-text p-button-secondary"></p-button> -->
            <span style="margin-left: 5px"></span>
            <p-button icon="pi pi-save" (click)="save()" *ngIf="selectedValues" styleClass="p-button-raised p-button-text p-button-secondary"></p-button>      
            <!-- <p-table 
            dir="rtl" 
            [value]="khatamat" 
            (sortFunction)="customSort($event)" 
            [customSort]="true"            
                [paginator]="true"
                [rows]="5" >
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="date" style="width:20%">التاريخ<p-sortIcon field="date"></p-sortIcon></th>
                        <th pSortableColumn="hizb" style="width:20%">الحزب <p-sortIcon field="hizb"></p-sortIcon></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-product>
                    <tr>
                        <td>{{product.date | date : 'dd/MM/yyyy'}}</td>
                        <td>{{product.hizb}}</td>
                    </tr>
                </ng-template>
            </p-table> -->
        </div>
        <div class="fontArab" dir="rtl" style="display: flex;flex-wrap: wrap; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-weight: bold;height: 815px;">
            <!-- <div id="ck-button" *ngFor="let item of Eaps" dir="rtl" [ngStyle]="item.nom ? {'background-color':  '#ffb732','color':'#fff'} : {'background-color':  '','color':'black'}">
                <label>
                    <input 
                    type="checkbox" 
                    [(ngModel)]="'item'+item.id"
                    value="{{item.id}}">
                    <span>الحـــزب {{'item'+item.id}}</span>
                </label>
            </div> -->
            <div id="ck-button" *ngFor="let item of Eaps" dir="rtl" [ngStyle]="item.nom ? {'background-color':  '#ffb732','color':'#fff'} : {'background-color':  '','color':'black'}">
                <label>
                    <!-- label=" الحـــزب {{item.id}}" -->
                    <p-checkbox 
                        [hidden]="true"
                        name="groupname" 
                        value="{{item.id}}"
                        [(ngModel)]="selectedValues" 
                        (onChange)="generateidHizb(selectedValues)">
                    </p-checkbox>
                    <span>الحـــزب {{item.id}}</span>
                </label>
            </div>
            <!-- <div *ngFor="let item of Eaps" style="padding: 5px;">
                <p-checkbox name="groupname" value="{{item.id}}" label=" الحـــزب {{item.id}}" [(ngModel)]="selectedValues" (onChange)="generateidHizb(selectedValues)" [style]="{'padding ': '25px'}"></p-checkbox>
            </div> -->
            
        </div>
    </div>    
</div>

