import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../data.service';
import { HttpClient } from '@angular/common/http';
import { MessageService, SortEvent } from 'primeng/api';
import { Eap } from '../model/eap';
import { AuthenticationService } from '../authentication.service';

@Component({
  selector: 'app-khatmaNext',
  templateUrl: './khatmaNext.component.html',
  styleUrls: ['./khatmaNext.component.scss'],  
  providers: [MessageService]
})
export class KhatmaNextComponent implements OnInit {

  Eap             : Eap = {};
  Eaps            : Eap[];
  khatamat        : Eap[];
  selectedValues  : string[] = [];  
  tablePlaning    : any[] = [];  
  blockedDocument : boolean;
  dataTable       : string;
  innerWidth      : number;
  innerHeight     : number;
  dateSituation   : Date;
  dateSit         : string;
  idHizb          : string;
  checked         : boolean;
  elementToClipBoard : string;
  constructor(
    private data: DataService,     
    public AuthenticationService: AuthenticationService,
    private http: HttpClient,
    private messageService: MessageService,
    private router: Router,
    ) { }

  ngOnInit(): void {
    this.dateSit = new Date().toISOString().split("T")[0];;
    // this.dateSit.setDate(this.dateSit.getDate() + 1);
    this.donnees();
    this.generatePlaning();
  }
  generatePlaning(){
    var req="INSERT INTO khatamatnext (date,hizb,idUser) VALUES ";
    var s = [
    {date:'2023-10-11',hizb:1,idUser:21},
    {date:'2023-10-11',hizb:3,idUser:14},
    {date:'2023-10-11',hizb:5,idUser:18},
    {date:'2023-10-11',hizb:7,idUser:25},
    {date:'2023-10-11',hizb:9,idUser:2},
    {date:'2023-10-11',hizb:11,idUser:19},
    {date:'2023-10-11',hizb:13,idUser:13},
    {date:'2023-10-11',hizb:15,idUser:12},
    {date:'2023-10-11',hizb:17,idUser:27},
    {date:'2023-10-11',hizb:19,idUser:8},
    {date:'2023-10-11',hizb:21,idUser:30},
    {date:'2023-10-11',hizb:23,idUser:5},
    {date:'2023-10-11',hizb:25,idUser:17},
    {date:'2023-10-11',hizb:27,idUser:11},
    {date:'2023-10-11',hizb:29,idUser:28},
    {date:'2023-10-11',hizb:31,idUser:16},
    {date:'2023-10-11',hizb:33,idUser:7},
    {date:'2023-10-11',hizb:35,idUser:26},
    {date:'2023-10-11',hizb:37,idUser:4},
    {date:'2023-10-11',hizb:39,idUser:20},
    {date:'2023-10-11',hizb:41,idUser:6},
    {date:'2023-10-11',hizb:43,idUser:24},
    {date:'2023-10-11',hizb:45,idUser:10},
    {date:'2023-10-11',hizb:47,idUser:29},
    {date:'2023-10-11',hizb:49,idUser:3},
    {date:'2023-10-11',hizb:51,idUser:1},
    {date:'2023-10-11',hizb:53,idUser:22},
    {date:'2023-10-11',hizb:55,idUser:9},
    {date:'2023-10-11',hizb:57,idUser:23},
    {date:'2023-10-11',hizb:59,idUser:15}];
    s.forEach((row,index)=>{
      this.dateSituation = new Date(row.date);
      var datee=this.dateSituation.getFullYear()+ "-" +  (this.dateSituation.getMonth()+ 1)+ "-" + this.dateSituation.getDate();
      var i=1;
      var j=row.hizb;
      for ( i = 1; i < 180; i += 2) {
        if(j>59){
          // i=1;
          j=1;
        }
        // console.log(j+'/'+(j+1));
        // req+="('"+datee+"','"+(j+1)+'/'+j+"',"+row.idUser+"),";
        req+="('"+datee+"','"+j+"',"+row.idUser+"),";
        j=j+2;
        this.dateSituation.setDate(this.dateSituation.getDate() + 1);
        var datee=this.dateSituation.getFullYear()+ "-" +  (this.dateSituation.getMonth()+ 1)+ "-" + this.dateSituation.getDate();
      }      
    });
    console.log(req);
    // s.forEach((row,index)=>{
    //   this.dateSituation = new Date(row.date);
    //   // this.dateSituation.setDate(this.dateSituation.getDate() + 1);
    //   var i=0;
    //   var hizb=row.hizb*1+1;
    //   for ( i = 1; i < 10; i += 3) {
    //     console.log(i);
        
    //     this.dateSituation.setDate(this.dateSituation.getDate() + 1);
    //     // console.log(this.dateSituation);        
    //     var datee=this.dateSituation.getFullYear()+ "-" +  (this.dateSituation.getMonth()+ 1)+ "-" + this.dateSituation.getDate()  ;
    //     if(hizb>=59){
    //       hizb=1;
    //     }else{
    //       hizb++;
    //     }        
    //     req+="('"+datee+"',"+hizb+","+row.idUser+"),";
    //   }
    // });
      // console.log(req);
  }
  donnees() {
    this.dataTable = "khatamat";
    this.Eap.action = "Select5jour";
    this.Eap.dateSit = this.dateSit;
    this.data
      .donnee(this.dataTable,this.Eap)
      .toPromise()
      .then((x: Eap[]) =>{
          this.Eaps = x;
          console.log(x);
          var dateSit = new Date(this.Eap.dateSit);
          const months = ["ینایر", "فبرایر", "مارس", "أبريل", "مای", "یونیو", "یولیوز", "غشت", "شتنبر", "أكتوبر", "نونبر", "دجنبر"];
          var nomPrenom="";
          var hizbNextAr="";
          var ancienCode=0;
          this.elementToClipBoard="🌻 السلام عليكم ورحمة الله وبركاته 🌻\nإعلان عن جدول الختمة لشهر💧"+dateSit.getDate()+"💧"+months[dateSit.getMonth()]+"🌹"+dateSit.getFullYear()+"🥀\n{قال رسول الله صلى الله عليه وسلم الماهر بالقرآن مع السفرة الكرام البررة}\n•┈┈•◈◉❀❒🌻❒❀◉◈•┈┈•\n🍯ختمة بتلاوة ورش 🍯\n*💛 المعلمة فاطمة مومن : *  اسماء الطاليبات\n";
          this.Eaps.forEach((row,index)=>{
            nomPrenom=row.nom+' '+row.prenom;
            // hizbNextAr=(row.hizb+1) +'/'+ row.hizb;               
            this.elementToClipBoard+="🍯*"+row.code+"*"+nomPrenom+" "+(row.hizb+1)+'/'+row.hizb+"🌹\n";
            // console.log(j); 
          });
          this.elementToClipBoard+="🥀🌹 سبحانك اللهم وبحمدك.. أشهد أن لا إله إلا أنت،أستغفرك وأتوب إليك🥀";
        });
  }

	// searchNextHizb(sort){
  //   const months = ["ینایر", "فبرایر", "مارس", "أبريل", "مای", "یونیو", "یولیوز", "غشت", "شتنبر", "أكتوبر", "نونبر", "دجنبر"];
  //   var code=9999;
  //   var codeHizb=9999;
  //   var codeHizbNext=9999;
  //   var hizbNextt=0;
  //   var hizbNext='';
  //   var hizbNextAr='';
  //   var ancienCode=9999;
  //   var nomPrenom='';
  //   // var table=[];
  //   var tableElement={};
  //   this.Eaps.forEach((row,index)=>{
  //     if(code==row.code){
  //       codeHizb++; 
  //       nomPrenom=row.nom+' '+row.prenom;
  //       ancienCode=row.code
  //       if(row.code==3){
  //         console.log(' ancienCode : '+ancienCode+ ' | codeHizb : '+codeHizb+'|  row.hizb : '+row.hizb+ '|  hizbNext : '+hizbNext);
  //       }  
  //       if(codeHizb==row.hizb){
  //         codeHizbNext=codeHizb+1;
  //         hizbNextt=codeHizbNext;
  //         hizbNext= codeHizbNext+ 'و'+(codeHizbNext*1+1);
  //         hizbNextAr=(codeHizbNext*1+1) + '/'+codeHizbNext;      
  //       }
  //       if(codeHizbNext>60){
  //         hizbNextt  =1;
  //         hizbNext  =2 +'و'+ 1;
  //         hizbNextAr=2 +'/'+ 1;
  //       }

  //       if(ancienCode==30 ){ 
  //         code=row.code;
  //         codeHizb=row.hizb*1;
  //         tableElement={'hizbNextt':hizbNextt,'hizbNextAr':hizbNextAr,'hizbNext':hizbNext,'ancienCode':ancienCode,'nomPrenom':nomPrenom};
  //       }
  //     }else{
  //       code     = row.code;
  //       codeHizb = row.hizb;
  //       if(row.code==3){
  //         console.log(' code : '+code+ ' | codeHizb : '+codeHizb+'|  row.hizb : '+row.hizb+ '|  hizbNext : '+hizbNext);
  //       }  
  //       tableElement={'hizbNextt':hizbNextt,'hizbNextAr':hizbNextAr,'hizbNext':hizbNext,'ancienCode':ancienCode,'nomPrenom':nomPrenom};
  //       this.Eaps[index].hizbNext=hizbNext+';'+ancienCode+';'+nomPrenom;
  //       this.tablePlaning.push(tableElement);
  //       // console.log(this.tablePlaning);        
  //       // this.elementToClipBoard+=this.tablePlaning.toString();
  //     }
  //   }); 
  //   if(ancienCode==30){
  //     // console.log(tableElement);
  //     this.tablePlaning.push(tableElement);
  //   }
  //   var theRemovedElement = this.tablePlaning.shift();  
  //   this.elementToClipBoard="🌻 السلام عليكم ورحمة الله وبركاته 🌻\nإعلان عن جدول الختمة لشهر💧"+this.dateSituation.getDate()+"💧"+months[this.dateSituation.getMonth()]+"🌹"+this.dateSituation.getFullYear()+"🥀\n{قال رسول الله صلى الله عليه وسلم الماهر بالقرآن مع السفرة الكرام البررة}\n•┈┈•◈◉❀❒🌻❒❀◉◈•┈┈•\n🍯ختمة بتلاوة ورش 🍯\n*💛 المعلمة فاطمة مومن : *  اسماء الطاليبات\n";
  //   // this.tablePlaning.sort();
  //   this.tablePlaning.sort(function(a, b) {
  //     var nameA = a.hizbNextt;
  //     var nameB = b.hizbNextt;   
  //     if(!sort){
  //       nameA = a.hizbNextt;
  //       nameB = b.hizbNextt;       
  //     }else{
  //       nameA = a.ancienCode; 
  //       nameB = b.ancienCode;        
  //     }

        
  //   // sort in an ascending order
  //     if (nameA < nameB) {
  //       return -1;
  //     }
  //     if (nameA > nameB) {
  //       return 1;
  //     }
    
  //     // names must be equal
  //     return 0;
  //   })
  //   this.tablePlaning.forEach((row,index)=>{
  //     this.elementToClipBoard+="🍯*"+row.ancienCode+"*"+row.nomPrenom+" "+row.hizbNextAr+"🌹\n";
  //   });
  //   this.elementToClipBoard+="🥀🌹 سبحانك اللهم وبحمدك.. أشهد أن لا إله إلا أنت،أستغفرك وأتوب إليك🥀";
  // }
  copyInputMessage(inputElement){
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }
}
