import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../environments/environment";
import { TreeNode } from 'primeng/api';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class DataService {
  myUrl: string = `${environment.urlApi}`;
  constructor(private http: HttpClient) {}
  
  public menu(data) {
    return this.http.post(`${this.myUrl}/API/API/menu.php`, data);
  }
  public fichier(data) {
    return this.http.post(`${this.myUrl}/API/API/fichier.php`, data);
  }
  posteFileMenu(id, type, chemin, fileToUpload: File) {
    const endpoint = `${this.myUrl}/API/API/uploadMenu.php`;
    const formData: FormData = new FormData();
    formData.append("image", fileToUpload, fileToUpload.name);
    formData.append("id", id);
    formData.append("label", type);
    // console.log(formData);
    return this.http.post(endpoint, formData);
  }
  posteFileRegle(id, type, chemin, fileToUpload: File) {
    const endpoint = `${this.myUrl}/API/API/uploadRegle.php`;
    const formData: FormData = new FormData();
    formData.append("image", fileToUpload, fileToUpload.name);
    formData.append("id", id);
    formData.append("label", type);
    // console.log(formData);
    return this.http.post(endpoint, formData);
  }
  posteFilePriere(id, type, chemin, fileToUpload: File) {
    const endpoint = `${this.myUrl}/API/API/uploadPriere.php`;
    const formData: FormData = new FormData();
    formData.append("image", fileToUpload, fileToUpload.name);
    formData.append("id", id);
    formData.append("label", type);
    // console.log(formData);
    return this.http.post(endpoint, formData);
  }
  posteFile(id,type,fileToUpload: File) {

    const endpoint = `${this.myUrl}/API/API/uploadPriere.php`;
    const formData: FormData = new FormData();
    formData.append("image", fileToUpload, fileToUpload.name);
    formData.append("id", id);
    formData.append("label", type);
    console.log(formData);
    return this.http.post(endpoint, formData);
  }

  // public getAgents(data) {
  //   return this.http.get(`${this.myUrl}/API/API/getAgents.php`, data);
  // }
  public getOrganigrame(data) {
    return this.http.post(`${this.myUrl}/API/API/getOrganigrame.php`, data);
  }
  public donnee(datatable,data) {
    return this.http.post(`${this.myUrl}/API/API/${datatable}.php`, data);
  }
  public insertGed(datatable,data) {
    return this.http.post(`http://safbpospc3.ormvah.local:1234/${datatable}.php`, data);
  }
  public getFilesystem(data) {
    return this.http.post<any>(`${this.myUrl}/API/API/getOrganigrame.php`, data)     
  }
  public eapIscription(data) {
    return this.http.post<any>(`${this.myUrl}/API/API/eapIscription.php`, data)     
  }
  public getAgents(data) {
    return this.http.post(`${this.myUrl}/API/API/getAgents.php`, data);
  }
  public getAgent(data) {
    return this.http.post(`${this.myUrl}/API/API/getAgent.php`, data);
  }
  public getAgentArabe(data) {
    return this.http.post(`${this.myUrl}/API/API/getAgentArabe.php`, data);
  }
}
